import React, { useContext, useState, useEffect, useRef, } from "react";
import {
  Card,
  Col,
  Radio,
  Row,
  Form,
  Select,
  DatePicker,
  Input,
  message,
  Skeleton,
  Tooltip,
  Modal,
  Collapse,
  Checkbox,
  Button,
  Statistic,
  Popover,
  Alert,
} from "antd";
import Slider from "react-slick";
import fliy from "../../assets/images/Icons/fli8-20.png"
import ssrmeal from "../../assets/images/ssr-meal.jpg"
import { Link, useHistory } from "react-router-dom";
import { useCurrencyContext } from "../../common/providers/CurrencyProvider";
import { GlobalStatesContext } from "../../common/providers";
import { useAuthContext } from "../../common/providers/AuthProvider";
import { useFlightContext } from "../../common/providers/Flights/FlightContext";
import { getPassengerData } from "../../helpers/PassegerData";
import FlightDetailsCard from "./Flight-Details-Card";
import FareSummaryCard from "./FareSummaryCard";
import BookPayCard from "./Book-Pay-Card";
import ApiClient from "../../helpers/ApiClient";
import { CloseCircleOutlined, InfoCircleOutlined, AppstoreOutlined, MailOutlined, PhoneOutlined, HomeTwoTone, UserOutlined, IdcardOutlined, ClockCircleOutlined, SolutionOutlined, LikeOutlined } from '@ant-design/icons';
import moment from "moment";
import admeal from "../../assets/images/Flighticons/icons8-food-50.png";
import adbag from "../../assets/images/Flighticons/bagg1.png";
import FlightGrandTotalCard from "./FlightGrandTotalCard";
import CountryList from "../../common/CountryList";
import ssrbag from "../../assets/images/baggs.jpg";
import seatIcon from "../../assets/images/Vrseat.png";
import seatSelect from "../../assets/images/Vrseatgreen.png";
import seatSmax from "../../assets/images/Vrseatred.png";
import seatPreff from "../../assets/images/Vrseatblue.png";
import seatOcc from "../../assets/images/Vrseatgray.png";
// import seatIcon from "../../assets/images/Vrseat.png";
import queryString from "query-string";
import { PaxAgeValidator } from "../../helpers/CustomValidators";
import adair from "../../assets/images/Flighticons/seat-air.png";
import {
  ArrowLeftOutlined,
  SafetyCertificateOutlined,
  CheckCircleOutlined
} from "@ant-design/icons";

import "./FlightsCheckout.scss";

const { Option } = Select;
const { Countdown } = Statistic;

let dateformat = "DD-MM-YYYY";
let oriDateFormat = "YYYY-MM-DD";

const validateMessages = {
  required: "",
};
const abbrevations = [
  "MR",
  "Mr",
  "mr",
  "mR",
  "MRS",
  "Mrs",
  "mrs",
  "mrS",
  "mRs",
  "MS",
  "ms",
  "Ms",
  "mS",
  "Mstr",
  "mstr",
  "Miss",
  "miss",
  ".",
];

const FlightCheckout = ({ location }) => {
  const {
    isLogin: { flag },
    isLogin: { agent },
    user,
  } = useAuthContext();
  // console.log(location, "checkout");
  const {
    state: {
      otherData: { insuranceData },
    },
    UpdateMealData,
    UpdateBaggageData,
    UpdatedSeatData,
    setSelectedInsuranceData,
    updateData,
    RemovePromoConvFee,
  } = useContext(GlobalStatesContext);
  const { Panel } = Collapse;
  const {
    state: { selectedFlight, flightSearchObj },
    updateFlightAirPrice,
    updateAirBookState,
    getsessiontimeout, state: { sessiontimeout, status },
  } = useFlightContext();

  let history = useHistory();
  const mobile = useRef();
  // const areaCode = useRef();
  const mobileRef = useRef(null);
  const areaCodeRef = useRef(null);
  const [showalldetails, setshowalldetails] = useState(false);
  const [showameal, setshowMeal] = useState(false);
  const [showbagg, setshowBagg] = useState(false);
  const [bagReq, setbagReq] = useState(false);
  const { activeCurrency, currencyValue } = useCurrencyContext();
  const [hoveredSeat, setHoveredSeat] = useState(null);
  const handleAreaCodeChange = () => {
    // When the area code changes, set the focus on the Mobile Number input field
    if (mobileRef.current) {
      mobileRef.current.focus();
    }
  };
  const onCheckChange = (e) => {
    // console.log(e, "Ele");
    setbagReq(e.target.checked);
    // setshowalldetails(true);

  };
  const [baggage, setBaggage] = useState([])
  const [farerules, setFarerules] = useState([])
  const [activeKey, setActiveKey] = useState(['1']);
  const [activeMeal, setactiveMeal] = useState(['0', '0']);
  const [activebagg, setactivebagg] = useState(['0', '0']);
  const [activeMealSegment, setactiveMealSegment] = useState(null);
  const [activebaggSegment, setactivebaggSegment] = useState(null);
  const onChange = (key) => {
    setActiveKey(key);
  };
  const [insuranceRequired, setInsuranceRequired] = useState(-1);
  const [totalPaxArr, setTotalPaxArr] = useState([]);
  const [contact_form] = Form.useForm();
  const [passenger_form] = Form.useForm();
  const [gst_form] = Form.useForm();
  // const [ssr_form] = Form.useForm();
  const [airPriceResp, setAirPriceResp] = useState({ flightDetails: [] });
  const [ssrResponse, setSsrResponse] = useState({ ssrDetails: [] });
  const [loadi,setLoadi]=useState(false)
  // const [totalPaxArr, setTotalPaxArr] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isGstRequired, setIsGstRequired] = useState(false);
  const [passengerRequiredFields, setpassengerRequiredFields] = useState({});
  const [additional_services, setAdditional_services] = useState([]);
  const [mealAdded, setmealAdded] = useState(false);
  const [bagAdded, setbagAdded] = useState(false);
  const [mealVisible, setmealVisible] = useState(false);
  const [mealPlanPaxArr, setmealPlanPaxArr] = useState([]);
  const [baggPaxArr, setbaggPaxArr] = useState([]);
  const [baggageVisible, setbaggageVisible] = useState(false);
  const [baggagePlanArr, setbaggagePlanArr] = useState([]);
  const [mealData, setMealData] = useState([]);
  const [baggData, setBaggData] = useState([]);
  const [SelectedServices, setSelcetedServices] = useState({
    0: { keys: [], services: [] },
    2: { keys: [], services: [] },
    1: { keys: [], services: [] },
    3: { keys: [], services: [] },
    4: { keys: [], services: [] },
    5: { keys: [], services: [] },
    6: { keys: [], services: [] },
    7: { keys: [], services: [] },
    8: { keys: [], services: [] },
  });

  const [travellersListResp, setTravellersListResp] = useState({
    Travellers: [],
  });

  const [seatPlanpaxArr, setSeatPlanpaxArr] = useState([])
  const [seatVisible, setSeatVisible] = useState(false);
  const [showSeat, setShowSeat] = useState(false);
  const [showPrice, setShowPrice] = useState(false)

  const [selectedPassenger, setSelectedPassenger] = useState(null);
  const [seatInfo, setSeatInfo] = useState(null);
  const [flight, setFlight] = useState(null);
  const [showFlightSSR, setShowFlightSSR] = useState(true);
  const [showFlightLayout, setShowFlightLayout] = useState(false);
  const [selectedSeat, setSelectedSeat] = useState([])
  const [passengers, setPassengers] = useState([]);
  const [seatData, setSeatData] = useState([])
  const [selectedSeats, setSelectedSeats] = useState({});
  const [seatAmount, setSeatAmount] = useState('')
  const [passengertype, setPassengertype] = useState('')
  const handlePassengerSelection = (index, paxType) => {
    setSelectedPassenger(index);
    setPassengertype(paxType);

  };

  const handleSeatSelection = (seatInfo, passengerType, passengerIndex, flightIndex, segmentIndex) => {
    const seatKey = `flight_${flightIndex}_segment_${segmentIndex}_passenger_${passengerIndex}`;
    const prevSelectedSeats = { ...selectedSeats[flightIndex] } || {};

    const isSeatSelected = Object.values(prevSelectedSeats).some(
      (selectedSeat) => selectedSeat?.code === seatInfo.code && selectedSeat?.segmentIndex === segmentIndex
    );

    const isSeatBookedOrReserved = seatInfo.availablityType === "Reserved" || seatInfo.availablityType === "CheckedIn";

    let seatAlreadySelected = false;

    // Check if the seat is already selected in the same segment of another flight
    Object.values(selectedSeats).forEach((flightSeats) => {
      Object.keys(flightSeats).forEach((key) => {
        if (flightSeats[key].code === seatInfo.code && flightSeats[key].segmentIndex === segmentIndex) {
          seatAlreadySelected = true;
        }
      });
    });

    if (!isSeatSelected && !isSeatBookedOrReserved) {
      prevSelectedSeats[seatKey] = { ...seatInfo, segmentIndex };
    } else {
      delete prevSelectedSeats[seatKey];
    }

    setSelectedSeats((prevState) => ({
      ...prevState,
      [flightIndex]: prevSelectedSeats,
    }));

    const updateSeatData = (seatInfo, passengerIndex, paxType, type) => {
      console.log(paxType, passengerIndex, type, "seee1");
      const temp = [...seatData];
      for (let i = 0; i < temp.length; i++) {
        for (let j = 0; j < temp[i].length; j++) {
          if (temp[i][j][passengerIndex]?.cityPair === `${seatInfo.origin}-${seatInfo.destination}`) {
            if (type === "add") {
              temp[i][j][passengerIndex] = {
                paxType: paxType + passengerIndex,
                amount: seatInfo?.price,
                seatCode: seatInfo?.code,
                seatDesc: seatInfo?.description,
                airlineCode: seatInfo?.airlineCode,
                flightNumber: seatInfo?.flightNumber,
                craftType: seatInfo?.craftType,
                cityPair: `${seatInfo.origin}-${seatInfo.destination}`,
                origin: seatInfo?.origin,
                destination: seatInfo?.destination,
                availablityType: seatInfo?.availablityType,
                rowNo: seatInfo?.rowNo,
                seatNo: seatInfo?.seatNo,
                seatType: seatInfo?.seatType,
                seatWayType: seatInfo?.seatWayType,
                compartment: seatInfo?.compartment,
                deck: seatInfo?.deck,
                currency: seatInfo?.currency,
                price: seatInfo?.price,
                ssId: seatInfo?.ssId,
              };
            } else if (type === "remove") {
              temp[i][j][passengerIndex] = {
                paxType: paxType + passengerIndex,
                amount: 0,
                seatCode: "",
                seatDesc: "",
                airlineCode: "",
                flightNumber: "",
                craftType: "",
                cityPair: `${seatInfo.origin}-${seatInfo.destination}`,
                origin: "",
                destination: "",
                availablityType: "",
                rowNo: "",
                seatNo: "",
                seatType: "",
                seatWayType: "",
                compartment: "",
                deck: "",
                currency: "",
                price: 0,
                ssId: "",
              };
            }
          }
        }
      }

      setSeatData(temp);

      const updatedSeatPlanPaxArr = [...seatPlanpaxArr];
      if (type === "add") {
        updatedSeatPlanPaxArr[passengerIndex] = {
          ...updatedSeatPlanPaxArr[passengerIndex],
          ...seatInfo,
        };
      } else if (type === "remove") {
        updatedSeatPlanPaxArr[passengerIndex] = {
          ...updatedSeatPlanPaxArr[passengerIndex],
          seatCode: "",
          amount: 0,
          airlineCode: "",
          flightNumber: "",
          craftType: "",
          cityPair: `${seatInfo.origin}-${seatInfo.destination}`,
          origin: "",
          destination: "",
          availablityType: "",
          rowNo: "",
          seatNo: "",
          seatType: "",
          seatWayType: "",
          compartment: "",
          deck: "",
          currency: "",
          ssId: "",
        };
      }

      setSeatPlanpaxArr(updatedSeatPlanPaxArr);
    };

    if (!isSeatSelected && !isSeatBookedOrReserved) {
      updateSeatData(seatInfo, passengerIndex, passengerType, "add");
    } else {
      updateSeatData(seatInfo, passengerIndex, passengerType, "remove");
    }
  };



  const [origin, setOrigin] = useState('')
  const [dest, setDest] = useState('')
  const handleOriginDestinationClick = (origin, destination) => {
    setOrigin(origin);
    setDest(destination)

  };

  const handleInsuranceChange = (val) => {
    if (val === 1) {
      setSelectedInsuranceData(insuranceData);
    } else {
      setSelectedInsuranceData({ amount: 0, insuranceCoverage: 0, status: 0 });
    }
    setInsuranceRequired(val);
  };
  const getFlightIds = () => {
    if (flightSearchObj?.airTravelType === "oneWay") {
      return [selectedFlight[0]?.flightId];
    } else {
      return selectedFlight?.map((flightID) => flightID?.flightId);
    }
  };
  // console.log(selectedFlight,"flllselect");
  // const handleOnSubmit = (ref) => {
  //   ref.current.focus();
  //   // if (ref === dateBox) {
  //   //   setShowDate(true);
  //   // }
  // };
  const CarouselPrevArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style, display: "inline-table", background: "#bd0c21" }}
        onClick={onClick}
      />
    );
  };
  const CarouselNextArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style, display: "inline-table", background: "#bd0c21" }}
        onClick={onClick}
      />
    );
  };
  // { console.log(activeMeal, "msr") }
  const settingsMeal = {
    infinite: true,
    className: "center",
    lazyLoad: true,
    // centerMode: true,
    slidesToShow: (ssrResponse?.specialServiceRequest?.[0]?.MEAL?.length ?? 0) > 5
      ? 5
      : (ssrResponse?.specialServiceRequest?.[0]?.MEAL?.length ?? 0),
    //autoplay: true,
    autoplaySpeed: 2000,
    //  speed:100,
    rows: 1,
    slidesPerRow: 1,
    pauseOnHover: true,
    arrows: true,
    nextArrow: <CarouselNextArrow />,
    prevArrow: <CarouselPrevArrow />,

  };
  const settings = {
    infinite: true,
    className: "center",
    lazyLoad: true,
    // centerMode: true,
    slidesToShow: ssrResponse?.specialServiceRequest?.[0]?.BAGGAGE?.length >= 2 ? 2 : ssrResponse?.specialServiceRequest?.[0]?.BAGGAGE?.length,
    autoplay: true,
    autoplaySpeed: 2000,
    rows: 1,
    slidesPerRow: 1,
    pauseOnHover: true,
    arrows: true,
    nextArrow: <CarouselNextArrow />,
    prevArrow: <CarouselPrevArrow />,
  };
  const MobileSlidersettings = {
    infinite: true,
    className: "center",
    lazyLoad: true,
    slidesToShow: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    rows: 1,
    slidesPerRow: 1,
    pauseOnHover: true,
    arrows: false,
  };


  const getSeatLayoutSSR = (airPriceResponse) => {
    let flightSegmentsData = {};

    // console.log(selectedFlight, flightSearchObj.airTravelType, "flllselect");
    if (flightSearchObj.airTravelType === "oneWay") {

      flightSegmentsData.one = airPriceResponse.flightDetails.flatMap(flight =>
        flight.flightSegments.map(segment => ({
          segId: segment.segId,
          origin: segment.origin,
          destination: segment.destination,
        }))
      );
    } else if (flightSearchObj.airTravelType === "roundTrip") {

      const oneData = selectedFlight?.[0]?.flightSegments.map(segment => ({
        segId: segment.segId,
        origin: segment.origin,
        destination: segment.destination,
      }));

      const returnData = selectedFlight?.[1]?.flightSegments.map(segment => ({
        segId: segment.segId,
        origin: segment.origin,
        destination: segment.destination,
      }));

      flightSegmentsData.one = oneData;

      flightSegmentsData.return = returnData;

    }
    else if (flightSearchObj.airTravelType === "multidestination") {
      const oneData = selectedFlight?.[0]?.flightSegments.map(segment => ({
        segId: segment.segId,
        origin: segment.origin,
        destination: segment.destination,
      }));

      const returnData = selectedFlight?.[1]?.flightSegments.map(segment => ({
        segId: segment.segId,
        origin: segment.origin,
        destination: segment.destination,
      }));
      if (selectedFlight?.[2]) {
        const thirdData = selectedFlight?.[2]?.flightSegments.map(segment => ({
          segId: segment.segId,
          origin: segment.origin,
          destination: segment.destination,
        }));
        flightSegmentsData.thirdroute = thirdData;
      }
      if (selectedFlight?.[3]) {
        const fourthData = selectedFlight?.[3]?.flightSegments.map(segment => ({
          segId: segment.segId,
          origin: segment.origin,
          destination: segment.destination,
        }));
        flightSegmentsData.fourthroute = fourthData;
      }

      flightSegmentsData.oneroute = oneData;
      flightSegmentsData.secondroute = returnData;

    }

    const seatLayoutSsrPayload = {
      traceId: airPriceResponse.traceId,
      flightIds: getFlightIds(),
      airTravelType: flightSearchObj.airTravelType,
      mappingType: flightSearchObj.resultsType,
      itineraryViewType: "1",
      tui: airPriceResponse.tui,
      segmentOriginDestinations: flightSegmentsData,
      tokenId: airPriceResponse.flightDetails[0].fareFamilies[0].supplierParameter,
      supplier: airPriceResponse.flightDetails[0].supplierKey,
    };

    ApiClient.post("flights/SEATLAYOUTSSR", seatLayoutSsrPayload)
      .then((resp) => {
        // console.log(resp);
        if (resp.statusCode === 200) {
          setShowFlightSSR(true);
          ssr_Services(resp.data);
          setSsrResponse(resp.data);
          GetMealData(resp?.data?.specialServiceRequest);
          var temp = getHeader(resp?.data?.specialServiceRequest?.[0]?.MEAL)[0]
          setactiveMealSegment(temp);
          var temp1 = getHeader(resp?.data?.specialServiceRequest?.[0]?.BAGGAGE)[0]
          setactivebaggSegment(temp1);
          //  console.log("iam not commming2");

        } else {
          setShowFlightSSR(false);
          //   console.log("iam not commming");
        }


      })
      .catch((err) => {
        console.error("Error fetching Seat Layout SSR:", err);
        setIsLoading(false);
      });
    setIsLoading(false);
  };

  const getAirPrice = () => {
    if (flightSearchObj) {
      let selectedFlightArray = [];
      if (flightSearchObj.airTravelType.toUpperCase() === "MULTIDESTINATION") {
        if (flightSearchObj.resultsType.toUpperCase() === "COMBINED" &&
          flightSearchObj.airTravelType.toUpperCase() === "MULTIDESTINATION") {
          selectedFlightArray.push({
            fareId: selectedFlight[0]?.fareFamilies.fareFamilies[0]?.fareId,
            flightId: selectedFlight[0]?.flightId,
            coupanType: selectedFlight[0]?.fareFamilies.fareFamilies[0]?.coupanType,
            fareType: selectedFlight[0]?.fareFamilies.fareFamilies[0]?.fareType,
            subCabinClass:
              selectedFlight[0].fareFamilies.fareFamilies[0]?.segmentInfos[0]?.cabinSubClass,
          });
        }
        else {

          selectedFlight.map((item, index) => {
            return selectedFlightArray.push({
              fareId: item.fareFamilies?.fareFamilies[0]?.fareId,
              flightId: item?.flightId,
              coupanType: item.fareFamilies?.fareFamilies[0]?.coupanType,
              fareType: item.fareFamilies.fareFamilies[0]?.fareType,
              subCabinClass:
                item.fareFamilies?.fareFamilies[0]?.segmentInfos[0]?.cabinSubClass,
            });
          });
        }
      } else {

        if (flightSearchObj.resultsType.toUpperCase() === "COMBINED" &&
          flightSearchObj.airTravelType.toUpperCase() === "ROUNDTRIP") {
          selectedFlightArray.push({
            fareId: selectedFlight[0]?.fareFamilies?.fareFamilies?.[0]?.fareId,
            flightId: selectedFlight[0]?.flightId,
            coupanType: selectedFlight[0]?.fareFamilies?.fareFamilies[0]?.coupanType,
            fareType: selectedFlight[0]?.fareFamilies?.fareFamilies[0]?.fareType,
            subCabinClass:
              selectedFlight[0]?.fareFamilies?.fareFamilies[0]?.segmentInfos[0]?.cabinSubClass,
          });
        } else {
          selectedFlight?.map((item, index) => {
            return selectedFlightArray.push({
              fareId: item?.fareFamilies?.fareFamilies[0]?.fareId,
              flightId: item.flightId,
              coupanType: item?.fareFamilies?.fareFamilies[0]?.coupanType,
              fareType: item?.fareFamilies?.fareFamilies[0]?.fareType,
              subCabinClass:
                item?.fareFamilies?.fareFamilies[0]?.segmentInfos[0]?.cabinSubClass,
            });

          });
        }

      }
      setIsLoading(true);


      let airPriceReqObj = {
        userId: user?.UserID ?? 1,
        roleType: user?.Role?.RoleId ?? 4,
        membership: user?.Membership ?? 1,
        traceId: flightSearchObj.traceId,
        flightIds: getFlightIds(),
        selectedFlights: selectedFlightArray,
        airTravelType: flightSearchObj.airTravelType,
        mappingType: flightSearchObj.resultsType,
        itineraryViewType: "1",
        gstDetails: {
          gstAddressLine1: "",
          gstAddressLine2: "",
          gstCity: "",
          gstState: "",
          gstpinCode: "",
          gstEmailId: "",
          gstNumber: "",
          gstPhoneNo: "",
          gstCompanyName: "",
        },
      };
      ApiClient.post("flights/airPrice", airPriceReqObj)
        // .then((resp) => {
        //   { console.log(resp, "airresp") }
        //   return resp;
        // })
        .then((resp) => {
          setUpFromApiResponse(resp);

        })
        .catch((err) => {


          setIsLoading(false);
          setShowFlightSSR(false);
        });
    } else {
      setIsLoading(false);
      setShowFlightSSR(false);
    }
  };

  const setUpFromApiResponse = (resp) => {
    // { console.log(resp.data.flightDetails, "fliresss") }
    if ((resp?.statusCode === 200 || resp?.statusCode === 1500) && resp?.data?.flightDetails != null) {
      getSeatLayoutSSR(resp.data);
      setLoadi(true);
      if(resp.data.specialServiceRequest.length>0){
      setBaggage(resp.data.specialServiceRequest)
      setFarerules(resp.data.specialServiceRequest[0])
      }
      if (resp?.data?.isPriceChange) {

        let cleanedMessage = resp.message.replace(/Previous Amt:[-\d]+ \| New Amt:[-\d]+/, '');
        Modal.confirm({
          title: 'Price Change',
          content: cleanedMessage,
          onOk() {

          },
          onCancel() {
            let queryParams = (JSON.parse(localStorage.getItem('FlightSearchBar')));
            history.push("/flight/results?" + queryParams);
          },
        });
      }
      setAirPriceResp(resp.data);
      paxInfoSections(resp.data);
      // ssr_Services(resp.data);
      updateFlightAirPrice(resp.data);
      // GetMealData(resp?.data?.specialServiceRequest);
      // var temp = getHeader(resp?.data?.specialServiceRequest?.[0]?.MEAL)[0]
      // setactiveMealSegment(temp);
      // var temp1 = getHeader(resp?.data?.specialServiceRequest?.[0]?.BAGGAGE)[0]
      // setactivebaggSegment(temp1);
    }

    else {
      // console.log("comming1");
      let cleanedMessage = "We're sorry, but we couldn't retrieve the information you requested.";
      Modal.confirm({
        title: 'please retry',
        content: cleanedMessage,
        className: "promo-modal-header modal-header-bg12",

        onOk() {
          let queryParams = (JSON.parse(localStorage.getItem('FlightSearchBar')));
          history.push("/flight/results?" + queryParams);
        },
        okText: 'Retry',
        cancelButtonProps: { style: { display: 'none' } },
      });
      setshowalldetails(false);
      setIsLoading(false);
      setShowFlightSSR(false);
    }

    setIsLoading(false);
  };


  // const onChangeModal = () => {
  //   console.log(baggageVisible,setmealVisible,seatVisible,"vissible");
  //   if (baggageVisible) {
  //     setbaggageVisible(false)
  //     setmealVisible(true)
  //     // setSeatVisible(true)
  //   }
  //   else if (setmealVisible) {
  //     setbaggageVisible(true)
  //     setmealVisible(false)
  //     // setSeatVisible(true)
  //   }
  //   else if (seatVisible) {
  //     setSeatVisible(false)
  //     setmealVisible(true)
  //     setbaggageVisible(true)
  //   }
  // }

  const onChangeModal = (modalType) => {
    console.log(baggageVisible, mealVisible, seatVisible, "vissible");
    // if (baggageVisible) {
    //   console.log("bagg");
    //   setbaggageVisible(false);
    //   setmealVisible(true);
    //   // setSeatVisible(true);  // Open meal modal and close baggage modal
    // } else if (mealVisible) {
    //   console.log("meal");
    //   setmealVisible(false);
    //   setbaggageVisible(true);
    //   // setSeatVisible(true);  // Open baggage modal and close meal modal
    // } else if (seatVisible) {
    //   console.log("seat");
    //   setbaggageVisible(false);
    //   setSeatVisible(false);
    //   setmealVisible(true);  // Open meal modal and close seat modal
    // }


    if (modalType === 'meal') {
      setmealVisible(true);
      setbaggageVisible(false);
      setSeatVisible(false);
    } else if (modalType === 'baggage') {
      setbaggageVisible(true);
      setmealVisible(false);
      setSeatVisible(false);
    } else if (modalType === 'seat') {
      setSeatVisible(true);
      setmealVisible(false);
      setbaggageVisible(false);
    }
  };

  const GetMealData = (data) => {
    let seatDataTempArr = [];
    let mealDataTempArr = [];
    let baggDatatempArr = [];
    if (data != null) {
      for (let i = 0; i < data?.length; i++) {
        let mealFlightObj = [];
        let bagFlightObj = [];
        let seatFlightObj = [];
        var connseatFlights = getHeaders(data[i]?.SEATDYNAMIC);
        var connFlights = getHeader(data[i]?.MEAL)
        var connBagFlights = getHeader(data[i]?.BAGGAGE)
        for (let j = 0; j < connFlights?.length; j++) {
          let mealConnObj = [];
          for (let k = 0; k < flightSearchObj?.adultCount + flightSearchObj?.childCount + flightSearchObj?.infantCount; k++) {
            let paxObj =
            {
              "amount": 0,
              "cityPair": connFlights[j],
              "paxType": "",
              "mealCode": "",
              "mealDesc": "",
              "flightNumber": ""

            }
            mealConnObj.push(paxObj)
          }
          mealFlightObj.push(mealConnObj)
        }
        mealDataTempArr.push(mealFlightObj);
        for (let j = 0; j < connBagFlights?.length; j++) {
          let baggConnObj = [];
          for (let k = 0; k < flightSearchObj?.adultCount + flightSearchObj?.childCount + flightSearchObj?.infantCount; k++) {
            let paxObj =
            {
              "amount": 0,
              "cityPair": connBagFlights[j],
              "paxType": "",
              "baggCode": "",
              "baggWeight": "",
              "flightNumber": ""

            }
            baggConnObj?.push(paxObj)
          }
          bagFlightObj?.push(baggConnObj)
        }
        baggDatatempArr?.push(bagFlightObj);
        for (let j = 0; j < connseatFlights?.length; j++) {
          let seatConnObj = [];
          for (let k = 0; k < flightSearchObj?.adultCount + flightSearchObj?.childCount + flightSearchObj?.infantCount; k++) {
            let paxObj = {
              "airlineCode": "",
              "flightNumber": "",
              "craftType": "",
              "origin": "",
              "destination": "",
              "availablityType": "",
              "description": "",
              "code": "",
              "rowNo": "",
              "seatNo": "",
              "seatType": "",
              "seatWayType": "",
              "compartment": "",
              "cityPair": connseatFlights[j],
              "deck": "",
              "currency": "",
              "price": 0,
              "paxType": "",
            }
            seatConnObj.push(paxObj);
          }
          seatFlightObj.push(seatConnObj);
        }
        seatDataTempArr.push(seatFlightObj);

      }
      setSeatData(seatDataTempArr);
      setMealData(mealDataTempArr);
      setBaggData(baggDatatempArr)
      // console.log("mealdataaa", mealDataTempArr, baggDatatempArr)
    }
  }
  const loadpassengerData = () => {
    if (user && user?.UserID) {
      getPassengerData(user.UserID).then((data) => {
        if (data.status) {
          contact_form.setFieldsValue({
            mobile: data.Mobile,
            email: data.Email,
            // areaCode: data?.DailingCode ? `+${data?.DailingCode}` : "",
            address: data?.Address1 ? data?.Address1 : "",
            city: data?.city ? data?.city : "",
            state: data?.state ? data?.state : "",
          });
        }
      });
    }

  };

  useEffect(() => {
    RemovePromoConvFee();
    getAirPrice();
    loadpassengerData();
    // ssr_Services();
    getsessiontimeout();
  }, []);

  useEffect(() => {
    fetchTravellerDetails();
  }, [user]);

  const handleGstRequired = (data) => {
    let count = 0;
    if (data?.flightDetails?.length > 0) {
      data.flightDetails.forEach((details) => {
        if (details?.fareFamilies?.length > 0) {
          if (details.fareFamilies[0].isGstMandatory) {
            count++;
          }
        }
      });
    }
    if (count > 0) {
      setIsGstRequired(true);
    }
  };

  const ssr_Services = (airSSRResp) => {
    const hasBaggage = airSSRResp?.specialServiceRequest?.some((item) => item?.BAGGAGE.length > 0);
    const hasMeal = airSSRResp?.specialServiceRequest?.some((item) => item?.MEAL.length > 0);
    const hasSeat = airSSRResp?.specialServiceRequest?.some((item) => item?.SEATDYNAMIC.length > 0);

    setshowalldetails(airSSRResp?.specialServiceRequest?.length > 0);
    setshowBagg(hasBaggage);
    setshowMeal(hasMeal);
    setShowSeat(hasSeat);
  };
  const paxInfoSections = (airPriceRespObj) => {
    // {console.log(airPriceResp,"famil2")}
    let paxInfoArr = [];
    let allPaxInfoArr = [];
    let mealPlanArr = [];
    let baggagePlanArr = [];
    let seatSelectionArr = [];
    for (let i = 0; i < flightSearchObj.adultCount; i++) {
      paxInfoArr.push({
        type: "ADT",
        pax: i + 1,
      });
    }

    for (let i = 0; i < flightSearchObj.childCount; i++) {
      paxInfoArr.push({
        type: "CHD",
        pax: i + 1,
      });
    }

    for (let i = 0; i < flightSearchObj.infantCount; i++) {
      paxInfoArr.push({
        type: "INF",
        pax: i + 1,
      });
    }
    if (airPriceRespObj.passengerRequiredFields.length > 0)
      setpassengerRequiredFields(airPriceRespObj.passengerRequiredFields[0]);
    paxInfoArr.map((obj, index) => {
      let mealPlanObj = {
        paxType: obj.type,
        index: index,
        mealplancode: "NoMeal",
        amount: 0,
        mealDesc: "",
        flightNumber: ""

      }
      let baggagePlanObj = {
        paxType: obj.type,
        index: index,
        baggagePlancode: "NoBagge",
        amount: 0,
        baggWeight: "",
        flightNumber: "",
      }
      let seatPlanObj = {
        paxType: obj.type,
        index: index,
        airlineCode: "",
        flightNumber: "",
        craftType: "",
        origin: "",
        destination: "",
        availablityType: "",
        description: "",
        code: "",
        rowNo: "",
        seatNo: "",
        seatType: "",
        seatWayType: "",
        compartment: "",
        cityPair: "",
        deck: "",
        currency: "",
        price: 0,

      }
      // console.log(seatPlanObj, "seatPlanObj");
      baggagePlanArr.push(baggagePlanObj);
      mealPlanArr.push(mealPlanObj);
      seatSelectionArr.push(seatPlanObj)
      setbaggagePlanArr(baggagePlanArr);
      setmealPlanPaxArr(mealPlanArr);
      //  console.log(seatPlanObj, seatSelectionArr,"seatPlanObj");
      setSeatPlanpaxArr(seatSelectionArr)
      let singlePaxObj = {
        title: obj.type === "ADT" ? "MR" : "MSTR",
        firstName: "",
        lastName: "",
        paxType: "",
        id: index,
        pax: obj.pax,
      };

      airPriceRespObj.passengerRequiredFields
        .filter((requiredFiledsObj) => requiredFiledsObj.paxType === obj.type)
        .map((paxReqFieldsObj) => {
          Object.keys(paxReqFieldsObj).map((paxReqKeys) => {
            if (
              paxReqFieldsObj[paxReqKeys] === true &&
              paxReqKeys !== "address"
            ) {
              singlePaxObj[paxReqKeys] = "";
              if (
                paxReqKeys === "passengerNationality" ||
                paxReqKeys === "passportIssuedCountry"
              ) {
                singlePaxObj[paxReqKeys] = "";
              }
            }
          });
          singlePaxObj["paxType"] = paxReqFieldsObj.paxType;
        });

      allPaxInfoArr.push(singlePaxObj);
    });

    setTotalPaxArr(allPaxInfoArr);
  };
  // const [mealDat, setMealDat] = useState([]);
  const mealPaxObj = (obj, k) => {
    let arr = [];
    let m = [];
    arr = mealData;
    // console.log(obj, k, (obj.paxType + k), "hellomydear");
    for (let i = 0; i < mealData?.length; i++) {
      m.push([]);
      for (let j = 0; j < mealData[i]?.length; j++) {
        m[i]?.push(arr[i]?.[j]?.filter(x => x?.paxType === (obj?.paxType + k)))
      }
    }
    // { console.log(m, "meal-3") }

    return m;
  }

  const baggPaxObj = (obj, k) => {
    let arr1 = [];
    let b = [];
    arr1 = baggData;
    // console.log(obj, k, (obj.paxType + k), "hellomydear");
    for (let i = 0; i < baggData?.length; i++) {
      b.push([]);
      for (let j = 0; j < baggData[i]?.length; j++) {
        b[i]?.push(arr1[i]?.[j]?.filter(x => x?.paxType === (obj?.paxType + k)))
      }
    }
    return b;
  }
  const seatPaxObj = (obj, s) => {
    // console.log(obj, s, seatData, "sssseat");
    let arr2 = [];
    let c = [];
    arr2 = seatData;

    for (let i = 0; i < seatData?.length; i++) {
      c.push([]);
      for (let j = 0; j < seatData[i]?.length; j++) {
        // console.log(arr2[i]?.[j], obj?.paxType, "cccheck123");
        c[i].push(arr2[i]?.[j]?.filter(x => x?.paxType === (obj?.paxType + s)))
      }
    }
    return c;
  };

  const redirectToPreview = () => {
    contact_form
      .validateFields()
      .then((contact_data) => {
        passenger_form
          .validateFields()
          .then((passegers_data) => {
            gst_form
              .validateFields()
              .then((gstDetails) => {
                onFlicghtCheckoutValidated(
                  contact_data,
                  passegers_data,

                  gstDetails
                );
              })
              .catch((e) => {
                console.log("gst form error", e);
              });
          })

          .catch((e) => {
            passenger_form.scrollToField(e.errorFields[0].name);
          });
      })
      .catch((e) => {
        if (!e.errorFields) {
          return;
        }
        contact_form.scrollToField(e.errorFields[0].name);
      });
  };

  const onFlicghtCheckoutValidated = (
    contactDetails,
    passegersData,
    gstDetails
  ) => {
    try {
      UpdateMealData(mealData);
      UpdateBaggageData(baggData);
      UpdatedSeatData(seatData);
      sessionStorage.setItem(
        "selected_services",
        JSON.stringify(SelectedServices)
      );

      let passegers_data = passegersData.passengers;
      passegers_data.map((passengersObj, i) => {
        passengersObj["mealPref"] = mealPaxObj(passengersObj, i);
        passengersObj["baggagePref"] = baggPaxObj(passengersObj, i);
        passengersObj["seatPref"] = seatPaxObj(passengersObj, i);
        // passengersObj["mealPref"] = mealPlanPaxArr[i]?.mealplancode
        // passengersObj["baggagePref"] = baggagePlanArr[i]?.baggagePlancode
        passengersObj["email"] = contactDetails.email;
        passengersObj["mobile"] = contactDetails.mobile;
        passengersObj["areaCode"] = contactDetails.areaCode;
        if (contactDetails.countryName) {
          let countryCode = CountryList.filter(
            (item) => item.name === contactDetails.countryName
          )[0].code;

          passengersObj["address_CountryCode"] = countryCode;
        } else {
          passengersObj["address_CountryCode"] = "";
        }
        if (passengersObj.dob) {
          passengersObj["dob"] = moment(passengersObj.dob).format(
            oriDateFormat
          );
        }
        if (passengersObj.passportDOE) {
          passengersObj["passportDOE"] = moment(
            passengersObj.passportDOE
          ).format(oriDateFormat);
        }
        if (passengersObj.passportDOI) {
          passengersObj["passportDOI"] = moment(
            passengersObj.passportDOI
          ).format(oriDateFormat);
        }

        passengersObj["address"] = contactDetails.address;
        passengersObj["city"] = contactDetails.city;
        passengersObj["countryName"] = contactDetails.countryName;
        if (contactDetails.state) {
          passengersObj["state"] = contactDetails.state;
        }

        if (contactDetails.postalCode) {
          passengersObj["postalCode"] = contactDetails.postalCode;
        }
        // passengersObj["seatPref"] = ssr_Services.seatCode;
        // passengersObj["mealPref"] = ssr_Services.mealCode;

        passengersObj["additionalServicesIds"] = SelectedServices[i].services;
        passengersObj["gender"] =
          passengersObj.title === "MR" || passengersObj.title === "MSTR"
            ? "m"
            : "f";
        delete passengersObj["countryCode"];
        delete passengersObj["id"];
        return passengersObj;
      });

      let airBookReqObj = {
        traceId: flightSearchObj.traceId,
        userId: user?.UserID ?? 1,
        roleType: user?.Role?.RoleId ?? 4,
        membership: user?.Membership ?? 1,
        passengers: passegers_data,
        additional_services: additional_services,
        gstDetails: {
          gstAddressLine1: gstDetails.gstAddressLine1
            ? gstDetails.gstAddressLine1
            : "",
          gstAddressLine2: "",
          gstCity: "",
          gstState: "",
          gstpinCode: "",
          gstEmailId: gstDetails.gstEmailId ? gstDetails.gstEmailId : "",
          gstNumber: gstDetails.gstNumber ? gstDetails.gstNumber : "",
          gstPhoneNo: gstDetails.gstPhoneNo ? gstDetails.gstPhoneNo : "",
          gstCompanyName: gstDetails.gstCompanyName
            ? gstDetails.gstCompanyName
            : "",
        },
        creditCardInfo: "",
        insuranceRequired: passegersData?.insuranceRequired === 1 ? 1 : 0,
        // insuranceData: selectedInsuranceData,
        // mealObj: mealData,
        // bagObj: baggData,
      };

      updateAirBookState(airBookReqObj);
      // console.log(airBookReqObj, "coming");
      history.push("/flight/review");
    } catch (error) {
      console.log(error);
      // alert(error.message + "hj")
    }
  };
  const disabledFutureDate = (currentDate) => {
    return currentDate > moment().startOf("day");
  };
  /* Fetching traveller details from API */
  const fetchTravellerDetails = () => {
    if (!user) return;
    ApiClient.get("admin/user/travellers/" + user.UserID)
      // .then((resp) => resp)
      .then((resp) => {
        // { console.log(resp, "userresp") }
        if (resp.status === 200) {
          setTravellersListResp(resp.data);
        } else {
          // console.log("Unable to fetch Travellers List");
        }
      })
      .catch((err) => err);
  };

  /* Handling traveler details append to form   */
  const handleTravelerDetails = (val, paxIndex) => {
    let selectedTraveler = travellersListResp.Travellers.find(
      (traveler) => traveler.TravellerID === val
    );

    let { passengers } = passenger_form.getFieldValue();
    let newObj = {
      ...passengers[paxIndex],
      title: selectedTraveler.Gender === 0 ? "MR" : "Ms",
      firstName: selectedTraveler.FirstName,
      lastName: selectedTraveler.LastName,
    };
    Object.keys(newObj).map((key) => {
      if (key === "dob") {
        newObj[key] = selectedTraveler.DOB ? moment(selectedTraveler.DOB) : "";
      }
      if (key === "passengerNationality") {
        newObj[key] = selectedTraveler?.PassengerNationality ?? selectedTraveler?.passportIssuedCountry;
      }
      if (key === "passportNumber") {
        newObj[key] = selectedTraveler.PassportNumber ?? "";
      }
      if (key === "passportDOI") {
        newObj[key] = selectedTraveler.PassportDOE
          ? moment(selectedTraveler.passportDOI)
          : "";
      }
      if (key === "passportDOE") {
        newObj[key] = selectedTraveler.PassportDOE
          ? moment(selectedTraveler.PassportDOE)
          : "";
      }
    });
    Object.assign(passengers[paxIndex], newObj);

    passenger_form.setFieldsValue({ passengers });
  };

  const GetIndex = (cityPair) => {

    let index = 0;
    for (let i = 0; i < mealData?.length; i++) {

      for (let j = 0; j < mealData[i]?.length; j++) {

        for (let k = 0; k < mealData?.[i]?.[j]?.length; k++) {
          if (mealData?.[i]?.[j]?.[k]?.cityPair === cityPair) {
            index = j;
            break;
          }
        }

      }
    }
    return index;
  }
  const GetIndx = (cityPair) => {
    let indx = 0;
    for (let i = 0; i < baggData?.length; i++) {

      for (let j = 0; j < baggData[i]?.length; j++) {

        for (let k = 0; k < baggData?.[i]?.[j]?.length; k++) {
          if (baggData?.[i]?.[j]?.[k]?.cityPair === cityPair) {
            indx = j;
            break;
          }
        }

      }
    }
    return indx;
  }
  // const updateKeys = (key, paxIndex) => {
  //   setSelcetedServices({
  //     ...SelectedServices,
  //     [paxIndex]: { keys: key, services: SelectedServices[paxIndex].services },
  //   });
  // };

  // const AddditionServiceHelper = ({ paxIndex, paxType }) => {
  //   // console.log("addtionalServices", SelectedServices);
  //   const addService = (e, _index, paxIndex) => {
  //     if (e.target.checked) {
  //       setSelcetedServices({
  //         ...SelectedServices,
  //         [paxIndex]: {
  //           keys: SelectedServices[paxIndex].keys,
  //           services: [...SelectedServices[paxIndex].services, _index],
  //         },
  //       });
  //     } else {
  //       setSelcetedServices({
  //         ...SelectedServices,
  //         [paxIndex]: {
  //           keys: SelectedServices[paxIndex].keys,
  //           services: SelectedServices[paxIndex].services.filter(
  //             (i) => i != _index
  //           ),
  //         },
  //       });
  //     }
  //   };

  //   return (
  //     <Row>
  //       <Col span={24}>
  //         <Collapse
  //           activeKey={SelectedServices[paxIndex].keys}
  //           onChange={(k) => updateKeys(k, paxIndex)}
  //         >
  //           <Panel header="Add baggage" collapsed>
  //             <Row>
  //               <Col span={24}>
  //                 {additional_services
  //                   .filter((i) => i.additionalServiceType == "Baggage")
  //                   .map((s) => {
  //                     return s.serviceDescription != 0 ? (
  //                       <Checkbox
  //                         onChange={(e) => addService(e, s._index, paxIndex)}
  //                         checked={
  //                           SelectedServices[paxIndex].services.indexOf(
  //                             s._index
  //                           ) != -1
  //                         }
  //                       >
  //                         {s.serviceDescription} for {activeCurrency}
  //                         {currencyValue(s.flightFares[0].amount)}
  //                       </Checkbox>
  //                     ) : null;
  //                   })}
  //               </Col>
  //             </Row>
  //           </Panel>
  //           <Panel header="Add Meal">
  //             <Row>
  //               <Col span={24}>
  //                 {additional_services
  //                   .filter((i) => i.additionalServiceType == "Meal")
  //                   .map((s) => {
  //                     return s.serviceDescription != 0 ? (
  //                       <Checkbox
  //                         onChange={(e) => addService(e, s._index, paxIndex)}
  //                         checked={
  //                           SelectedServices[paxIndex].services.indexOf(
  //                             s._index
  //                           ) != -1
  //                         }
  //                       >
  //                         {s.serviceDescription} for {activeCurrency}
  //                         {currencyValue(s.flightFares[0].amount)}
  //                       </Checkbox>
  //                     ) : null;
  //                   })}
  //               </Col>
  //             </Row>
  //           </Panel>
  //           <Panel header="Add Special Services">
  //             <Row>
  //               <Col span={24}>
  //                 {additional_services
  //                   .filter((i) => i.additionalServiceType == "SpecialService")
  //                   .map((s) => {
  //                     return s.serviceDescription != 0 ? (
  //                       <Checkbox
  //                         onChange={(e) => addService(e, s._index, paxIndex)}
  //                         checked={
  //                           SelectedServices[paxIndex].services.indexOf(
  //                             s._index
  //                           ) != -1
  //                         }
  //                       >
  //                         {s.serviceDescription} for {activeCurrency}
  //                         {currencyValue(s.flightFares[0].amount)}
  //                       </Checkbox>
  //                     ) : null;
  //                   })}
  //               </Col>
  //             </Row>
  //           </Panel>
  //         </Collapse>
  //       </Col>
  //     </Row>
  //   );
  // }; 
  // const onChangeModal = () => {
  //   if (baggageVisible) {
  //     setbaggageVisible(false)
  //     setmealVisible(true)
  //   }
  //   else if (setmealVisible) {
  //     setbaggageVisible(true)
  //     setmealVisible(false)
  //   }
  // }

  const [mcode, setMcode] = useState([]);
  const [madd, setMAdd] = useState(false);
  const [badd, setBAdd] = useState(false);
  const [bcode, setBcode] = useState([]);
  const [mealSelection, setMealSelection] = useState({});
  const [baggSelection, setBaggSelection] = useState({});
  const getmealcode = (selectedMeal, index, paxType, type) => {
    // console.log("venuGG", selectedMeal, mealData, type)
    for (let i = 0; i < mealData?.length; i++) {
      for (let j = 0; j < mealData[i]?.length; j++) {
        //console.log("temppppp", mealData[i].[j].[index])
        if (mealData[i]?.[j]?.[index]?.cityPair === selectedMeal?.cityPair) {
          let temp = [];
          temp = mealData;
          if (type === "add") {
            temp[i][j][index].paxType = paxType;
            temp[i][j][index].amount = selectedMeal?.amount;
            temp[i][j][index].mealCode = selectedMeal?.code;
            temp[i][j][index].mealDesc = selectedMeal?.airlineDesc;
            temp[i][j][index].flightNumber = selectedMeal?.flightNumber;
          } else if (type === "remove") {
            // Your removal logic here, e.g., set mealCode and mealDesc to null
            temp[i][j][index].paxType = null;
            temp[i][j][index].amount = null;
            temp[i][j][index].mealCode = null;
            temp[i][j][index].mealDesc = null;
            temp[i][j][index].flightNumber = null;
          }
          setMealData(temp);
        }
      }
      // { console.log(temp, "meal-ve") }
    }
    // console.log("mealsel", mealData)

    let temp = [];
    temp = mealPlanPaxArr;
    temp[index].mealplancode = selectedMeal?.code;
    temp[index].amount = selectedMeal?.amount
    setmealPlanPaxArr(temp)
    setMcode(temp[index].mealplancode)
    setMAdd(true)
    setMealSelection((prevSelection) => {
      return {
        ...prevSelection,
        [index]: selectedMeal.code,
      };
    });
  }


  const getbaggcode = (selectedBagg, index, paxType, type) => {

    for (let i = 0; i < baggData?.length; i++) {
      for (let j = 0; j < baggData[i]?.length; j++) {
        // console.log("pppp", selectedBagg)
        if (baggData[i]?.[j]?.[index]?.cityPair === selectedBagg?.cityPair) {
          let temp = [];
          temp = baggData;
          if (type === "add") {
            temp[i][j][index].paxType = paxType;
            temp[i][j][index].amount = selectedBagg?.amount;
            temp[i][j][index].baggCode = selectedBagg?.code;
            temp[i][j][index].baggWeight = selectedBagg?.weight;
            temp[i][j][index].flightNumber = selectedBagg?.flightNumber;
          } else if (type === "remove") {

            temp[i][j][index].paxType = null;
            temp[i][j][index].amount = null;
            temp[i][j][index].baggCode = null;
            temp[i][j][index].baggWeight = null;
            temp[i][j][index].flightNumber = null;
          }
          setBaggData(temp);
          // { console.log(temp, "bag-ve") }
        }
      }
    }
    // console.log("baggData", baggData)

    let temp = [];
    temp = baggagePlanArr;
    temp[index].bagplancode = selectedBagg?.code;
    temp[index].amount = selectedBagg?.amount
    setbaggagePlanArr(temp)
    setBcode(temp[index].bagplancode)
    setBAdd(true)
    setBaggSelection((prevSelection) => {
      return {
        ...prevSelection,
        [index]: selectedBagg.code,
      };
    });
  }



  const getHeader = (trip) => {
    let arr = [];
    trip?.forEach(e => {
      if (!arr?.includes(e?.cityPair))
        arr?.push(e?.cityPair)
    })
    // console.log(arr, "arr")
    return arr;
  }
  const getHeaders = (seatDynamic) => {
    let arr = [];
    seatDynamic?.[0]?.SSRSEATS.map((seat) => {
      if (seat.SegmentSeat) {
        seat.SegmentSeat.forEach(segment => {
          const cityPair = `${segment.RowSeats[1].Seats[0].origin}-${segment.RowSeats[1].Seats[0].destination}`;
          if (!arr.includes(cityPair)) {
            arr.push(cityPair);
          }
        });
      }
    });
    // console.log(arr, "array")
    return arr;
  }
  const handelCountdown = () => {
    // const query = queryString.stringify(flightsearchsessionobj);
    Modal.warning({
      icon: <ClockCircleOutlined />,
      //title: "",
      content: (<div><h5>Your Session is Expired</h5><p>Click on "OK" to continue with New Search</p></div>),
      onOk() {

        let queryParams = (JSON.parse(localStorage.getItem('FlightSearchBar')));
        history.push("/flight/results?" + queryParams);
      },
    });
  };
   console.log(loadi,"jjjhh")
  return (
    <div>
      <div className="checkout-heading">
        <div style={{ marginTop: "65px" }} fluid className="checkout-heading-container">
          <div className="goback">
            <Link
              onClick={() => {
                history.go(-1);
              }}
            >
              <ArrowLeftOutlined />
              <span>Go back and select another flight</span>
            </Link>
          </div>
          <h3>Fill out the form below and book your flight now!</h3>
        </div>
      </div>

      <div className="checkout-section">
        {/* {status != 0 &&
          <div className="countdown3">
            <div className="countdown-main-box">
              <div className="countdown1">
                <span class="fa fa-clock-o" style={{ fontSize: "18px" }}></span><span> Your session will expire in</span>
              </div>
              <div className="countdown2">
                <Countdown
                  className="busCountdown"
                  onFinish={handelCountdown}
                  value={sessiontimeout}
                  format={"mm[ min]  ss[ sec]"}
                />
              </div>
            </div>
          </div>} */}
        <div className="flight-checkout">
          <section className="checkout-body-sec">

            <div className="checkout-container flights-poins-sec">
              <Row gutter={[16, 16]}>
                <Col className="checkout-bottom-part">
                  {/* {isLoading ? (
                    <>

                      <Card className="checkout-custom-card">
                        <Skeleton active />
                      </Card>
                    </>
                  ) : ( */}

                  < FlightDetailsCard
                    selectedFlight={selectedFlight}
                    flightSearchObj={flightSearchObj}
                    travelType={flightSearchObj.airTravelType}
                    baggage={baggage}
                    farerules={farerules}
                    isLoadi={loadi}
                  />
                  {/* )} */}

                  <div className="user-details contactwrapper margin-contact">
                    <div className="titlewrapper">
                      <Card className="flight-cards-details">
                        <div className="inner-part">
                          {isLoading ? (
                            <>
                              <p className="heading">Contact Details</p>
                              <Card className="checkout-custom-card">
                                <Skeleton active />
                              </Card>
                            </>
                          ) : (
                            <>
                              <p className="heading">Contact Details</p>
                              <Form
                                layout="vertical"
                                className="flight-checkout-contact-form"
                                name="contactForm"
                                form={contact_form}
                                scrollToFirstError={true}
                                initialValues={{ areaCode: agent ? "+91" : "" }}
                              >
                                <Row gutter={[16, 8]}>
                                  <Col md={12} sm={12} xs={24}>
                                    <Form.Item
                                      label="Phone Number"
                                      name="mobile"
                                      className="phno"
                                      rules={[
                                        {
                                          required: true,
                                          message: "Phone Number  Required",
                                        },
                                        // {
                                        //   minLength: 10,
                                        //   maxLength: 10,
                                        //   pattern: "^[0-9]{10}$",
                                        //   message: "Must be 10 digits",
                                        // },
                                      ]}
                                    >
                                      <Input
                                        ref={mobileRef}
                                        addonBefore={
                                          <Form.Item
                                            style={{ width: "35%" }}
                                            name="areaCode"
                                            className="phno"
                                            rules={[
                                              {
                                                required: true,
                                                // message: "Phone Number Code Required",
                                              },
                                            ]}

                                            noStyle

                                          >
                                            <Select
                                              placeholder="Select ISD"
                                              showSearch
                                              showArrow={false}
                                              onChange={handleAreaCodeChange}
                                              ref={areaCodeRef}
                                              // handleOnSubmit={handleOnSubmit}
                                              style={{ width: "100%" }}
                                              filterOption={(input, option) =>
                                                option.children
                                                  .toLowerCase()
                                                  .indexOf(input.toLowerCase()) >= 0
                                              }
                                              // refName={areaCode}
                                              focusRef={mobile}
                                            >
                                              {CountryList.map((item) => (
                                                <Option
                                                  key={item.dial_code}
                                                  value={item.dial_code}
                                                >
                                                  {item.dial_code}
                                                </Option>
                                              ))}
                                            </Select>
                                          </Form.Item>
                                        }
                                        className="inputbg"
                                        placeholder="Enter Mobile Number"
                                        onKeyPress={(event) => {
                                          if (!/[0-9]/.test(event.key)) {
                                            event.preventDefault();
                                          }
                                        }}
                                        size={"large"}
                                      />
                                    </Form.Item>
                                  </Col>

                                  <Col md={12} sm={12} xs={24}>
                                    <Form.Item
                                      name="email"
                                      label="Email"
                                      rules={[
                                        { required: true, message: "Required" },
                                        { type: "email", message: "Invalid Email" },
                                      ]}
                                    >
                                      <Input type={"email"} className="inputbg"
                                        size={"large"}
                                        placeholder="Enter Email Address" />
                                    </Form.Item>{" "}
                                  </Col>
                                  {passengerRequiredFields.hasOwnProperty("address")
                                    ? passengerRequiredFields.address === true && (
                                      <Col md={12} sm={12} xs={24}>
                                        <Form.Item
                                          name="address"
                                          // name="city"
                                          label="Address"
                                          rules={[
                                            {
                                              required: true,
                                              message: "Required",
                                            },
                                            { min: 3, message: "Invalid Address" },
                                          ]}
                                        >
                                          <Input className="inputbg"
                                            size={"large"}
                                            placeholder="Enter Current Address" />
                                        </Form.Item>
                                      </Col>
                                    )
                                    : null}
                                  {passengerRequiredFields.hasOwnProperty("city")
                                    ? passengerRequiredFields.city === true && (
                                      <Col md={12} sm={12} xs={24}>
                                        <Form.Item
                                          name="city"
                                          label="City"
                                          rules={[
                                            { required: true, message: "Required" },
                                          ]}
                                        >
                                          <Input className="inputbg"
                                            size={"large"}
                                            placeholder="Enter Your City" />
                                        </Form.Item>{" "}
                                      </Col>
                                    )
                                    : null}
                                  {/* {passengerRequiredFields.hasOwnProperty("state")
                              ? passengerRequiredFields.state === true && (
                                <Col md={12} sm={12} xs={24}>
                                  <Form.Item
                                    name="state"
                                    label="State"
                                    rules={[
                                      { required: true, message: "Required" },
                                    ]}
                                  >
                                    <Input className="inputbg" />
                                  </Form.Item>{" "}
                                </Col>
                              )
                              : null} */}
                                  {/* {passengerRequiredFields.hasOwnProperty(
                              "countryName"
                            ) ||
                              passengerRequiredFields.hasOwnProperty(
                                "countryCode"
                              ) ? (
                              passengerRequiredFields.countryName === true ||
                                passengerRequiredFields.countryCode === true ? (
                                <Col md={12} sm={12} xs={24}>
                                  <Form.Item
                                    name="countryName"
                                    label="Country"
                                    rules={[
                                      { required: true, message: "Required" },
                                    ]}
                                  >
                                    <Select
                                      showSearch
                                      placeholder="Select Country"
                                      style={{ width: "100%" }}
                                      filterOption={(input, option) =>
                                        option.children
                                          .toLowerCase()
                                          .indexOf(input.toLowerCase()) >= 0
                                      }
                                    >
                                      {CountryList.map((item) => (
                                        <Option
                                          key={item.name}
                                          value={item.name}
                                        >
                                          {item.name}
                                        </Option>
                                      ))}
                                    </Select>
                                  </Form.Item>
                                </Col>
                              ) : null
                            ) : null} */}
                                  {/* {passengerRequiredFields.hasOwnProperty(
                              "postalCode"
                            )
                              ? passengerRequiredFields.postalCode === true && (
                                <Col md={12} sm={12} xs={24}>
                                  <Form.Item
                                    name="postalCode"
                                    label="Postal Code"
                                    rules={[
                                      { required: true, message: "Required" },
                                    ]}
                                  >
                                    <Input className="inputbg" />
                                  </Form.Item>
                                </Col>
                              )
                              : null} */}
                                </Row>
                              </Form>
                            </>)
                          }
                        </div>
                        <div className="inner-part-pass">
                          {isLoading ? (
                            <>
                              <p className="heding1">Passenger Details</p>
                              <Card className="checkout-custom-card">
                                <Skeleton active />
                              </Card>
                            </>
                          ) : totalPaxArr.length > 0 ? (
                            <>
                              <p className="heding1">Passenger Details</p>

                              <Form
                                layout="vertical"
                                className="passenger-form user-details "
                                validateMessages={validateMessages}
                                form={passenger_form}
                                initialValues={{ passengers: totalPaxArr }}
                              >
                                <Form.List name="passengers">
                                  {(fields) => {
                                    return fields.map((field, index) => (
                                      <>
                                        <Row className="pax-infobox" gutter={[16]}>
                                          <Col md={4} sm={8} xs={24}>
                                            {/* <div className="pax-heading">
                                              {totalPaxArr[index].paxType === "ADT"
                                                ? "Adult"
                                                : totalPaxArr[index].paxType ===
                                                  "CHD"
                                                  ? "Child"
                                                  : "Infant"}
                                            </div> */}
                                            <div className="pax-head-title">
                                              {/* {console.log(field,totalPaxArr,"pax")} */}
                                              {totalPaxArr[index].paxType === "ADT"
                                                ? <Tooltip placement="right" title={"12+ years"}>{"Adult - " + totalPaxArr[index].pax}</Tooltip>
                                                : totalPaxArr[index].paxType ===
                                                  "CHD"
                                                  ? <Tooltip placement="right" title={"2-12 yrs"}>{"Child - " + totalPaxArr[index].pax}</Tooltip>
                                                  : <Tooltip placement="right" title={"0-2 yrs"}>{"Infant - " + totalPaxArr[index].pax}</Tooltip>}
                                            </div>

                                          </Col>
                                          <Col md={18} sm={8} xs={24}>
                                            <div className="pax-details">
                                              {flag && (
                                                <>
                                                  <Col md={10} sm={8} xs={24}>
                                                    <Select
                                                      placeholder="Choose Passenger"
                                                      onSelect={(val) => {
                                                        handleTravelerDetails(
                                                          val,
                                                          index
                                                        );
                                                      }}
                                                      style={{ width: "100%" }}
                                                    >
                                                      {travellersListResp.Travellers.map(
                                                        (travelerName) => (
                                                          <Option
                                                            value={
                                                              travelerName.TravellerID
                                                            }
                                                            key={
                                                              travelerName.TravellerID
                                                            }
                                                          >
                                                            {travelerName.FirstName +
                                                              " " +
                                                              travelerName.LastName}
                                                          </Option>
                                                        )
                                                      )}
                                                    </Select>
                                                  </Col>
                                                </>
                                              )}
                                            </div>
                                          </Col>
                                          {/* {flag && (
                                            <>
                                              <Col md={13} sm={8} xs={24}>
                                                <div className="pax-head-hr">
                                                  <hr />
                                                </div>
                                              </Col>

                                              <Col md={8} sm={8} xs={24}>
                                                <Select
                                                  placeholder="Choose"
                                                  onSelect={(val) => {
                                                    handleTravelerDetails(
                                                      val,
                                                      index
                                                    );
                                                  }}
                                                  style={{ width: "100%" }}
                                                >
                                                  {travellersListResp.Travellers.map(
                                                    (travelerName) => (
                                                      <Option
                                                        value={
                                                          travelerName.TravellerID
                                                        }
                                                        key={
                                                          travelerName.TravellerID
                                                        }
                                                      >
                                                        {travelerName.FirstName +
                                                          " " +
                                                          travelerName.LastName}
                                                      </Option>
                                                    )
                                                  )}
                                                </Select>
                                              </Col>
                                            </>
                                          )} */}
                                        </Row>
                                        <Row gutter={[16, 8]}>
                                          <Col md={4} sm={8} xs={24}>
                                            <Form.Item
                                              {...field}
                                              name={[index, "title"]}
                                              label="Title"
                                              rules={[
                                                {
                                                  required: true,
                                                  message: "Specify Title",
                                                },
                                              ]}
                                            >
                                              <Select
                                                size={"large"}
                                                placeholder="Title"
                                                rules={[
                                                  {
                                                    required: true,
                                                    message: "Required",
                                                  },
                                                ]}
                                              >
                                                {totalPaxArr[index].paxType ===
                                                  "ADT" ? (
                                                  <>
                                                    <Select.Option value="MR">
                                                      Mr
                                                    </Select.Option>
                                                    <Select.Option value="MRS">
                                                      Mrs
                                                    </Select.Option>
                                                  </>
                                                ) : (
                                                  <>
                                                    <Select.Option value="MSTR">
                                                      Mstr
                                                    </Select.Option>
                                                  </>
                                                )}

                                                <Select.Option value="MS">
                                                  Ms
                                                </Select.Option>
                                              </Select>
                                            </Form.Item>
                                          </Col>
                                          <Col md={8} sm={8} xs={24}>
                                            <Form.Item
                                              {...field}
                                              name={[index, "firstName"]}
                                              label="First Name"
                                              rules={[
                                                {
                                                  required: true,
                                                  message: "Required",
                                                },
                                                {
                                                  pattern: "^[a-zA-Z ]*$",
                                                  message:
                                                    "Alphabet Characters Only",
                                                },
                                                {
                                                  min: 2,
                                                  message:
                                                    "First Name must be minimum 2 characters.",
                                                },
                                                {
                                                  max: 32,
                                                  message:
                                                    "First Name must be maximum 32 characters.",
                                                },
                                                ({ getFieldValue }) => ({
                                                  validator(_, value) {
                                                    if (
                                                      !value ||
                                                      getFieldValue([
                                                        "passengers",
                                                        index,
                                                        "lastName",
                                                      ]) != value
                                                    ) {
                                                      if (
                                                        !abbrevations.includes(
                                                          value
                                                        )
                                                      ) {
                                                        return Promise.resolve();
                                                      } else {
                                                        return Promise.reject(
                                                          new Error(
                                                            "Abbrevations are not accepted in beginning."
                                                          )
                                                        );
                                                      }
                                                    }
                                                    return Promise.reject(
                                                      new Error(
                                                        "The First and Last Name should not be same."
                                                      )
                                                    );
                                                  },
                                                }),
                                              ]}
                                            >
                                              <Input className="inputbg"
                                                size={"large"}
                                                placeholder="Enter Your First Name" />
                                            </Form.Item>
                                          </Col>
                                          <Col md={6} sm={8} xs={24}>
                                            <Form.Item
                                              {...field}
                                              name={[index, "lastName"]}
                                              label="Last Name"
                                              rules={[
                                                {
                                                  required: true,
                                                  message: "Required",
                                                },
                                                {
                                                  min: 2,
                                                  message:
                                                    "Last Name must be minimum 2 characters.",
                                                },
                                                {
                                                  max: 32,
                                                  message:
                                                    "Last Name must be maximum 32 characters.",
                                                },
                                                {
                                                  pattern: "^[a-zA-Z ]*$",
                                                  message:
                                                    "Alphabet Characters Only",
                                                },
                                                ({ getFieldValue }) => ({
                                                  validator(_, value) {
                                                    if (
                                                      !value ||
                                                      getFieldValue([
                                                        "passengers",
                                                        index,
                                                        "firstName",
                                                      ]) != value
                                                    ) {
                                                      if (
                                                        !abbrevations.includes(
                                                          value
                                                        )
                                                      ) {
                                                        return Promise.resolve();
                                                      } else {
                                                        return Promise.reject(
                                                          new Error(
                                                            "Abbrevations are not accepted in beginning."
                                                          )
                                                        );
                                                      }
                                                    }
                                                    return Promise.reject(
                                                      new Error(
                                                        "The First and Last Name should not be same."
                                                      )
                                                    );
                                                  },
                                                }),
                                              ]}
                                            >
                                              <Input className="inputbg"
                                                size={"large"}
                                                placeholder="Enter Your Last Name" />
                                            </Form.Item>
                                          </Col>

                                          {totalPaxArr[index].hasOwnProperty(
                                            "dob"
                                          ) && (
                                              <Col md={6} sm={8} xs={24}>
                                                <Form.Item
                                                  label="Date of Birth"
                                                  name={[index, "dob"]}
                                                  rules={[
                                                    {
                                                      required: true,
                                                      message: "Required",
                                                    },
                                                    {
                                                      validator: (_, v) =>
                                                        PaxAgeValidator(
                                                          totalPaxArr[index].paxType,
                                                          v
                                                        ),
                                                    },
                                                  ]}
                                                >
                                                  <DatePicker
                                                    className="inputbg"
                                                    allowClear={false}
                                                    format={dateformat}
                                                    style={{ width: "100%" }}
                                                    disabledDate={disabledFutureDate}
                                                    placeholder="DD-MM-YYYY"
                                                    size={"large"}
                                                  />
                                                </Form.Item>
                                              </Col>
                                            )}

                                          {totalPaxArr[index].hasOwnProperty("passengerNationality")
                                            && (
                                              <Col md={6} sm={8} xs={24}>
                                                <Form.Item
                                                  {...field}
                                                  name={[
                                                    index,
                                                    "passengerNationality",
                                                  ]}

                                                  label="Nationality"
                                                  rules={[
                                                    {
                                                      required: true,
                                                      message: "Required",
                                                    },
                                                    // {
                                                    //   pattern: "^[a-zA-Z ]*$",
                                                    //   message:
                                                    //     "Alphabet Characters Only",
                                                    // },
                                                  ]}
                                                >

                                                  {/* <Input className="inputbg" /> */}
                                                  <Select
                                                    size="large"
                                                    style={{ width: "100%" }}
                                                    placeholder="Select Country"
                                                    filterOption={(input, option) =>
                                                      option.children
                                                        .toLowerCase()
                                                        .indexOf(input.toLowerCase()) >= 0
                                                    }
                                                    showSearch
                                                  >
                                                    {CountryList.map((item) => (
                                                      <Option
                                                        key={item.name}
                                                        value={item.code}
                                                      >
                                                        {item.name}
                                                      </Option>
                                                    ))}
                                                  </Select>
                                                </Form.Item>
                                              </Col>
                                            )}
                                          {/* {console.log(totalPaxArr[index], "familcheck")} */}
                                          {totalPaxArr[index].hasOwnProperty(
                                            "passportNumber"
                                          ) && (
                                              <Col md={6} sm={8} xs={24}>
                                                <Form.Item
                                                  name={[index, "passportNumber"]}
                                                  label="Passport No"
                                                  rules={[
                                                    {
                                                      required: true,
                                                      message: "Required",
                                                    },
                                                  ]}
                                                >
                                                  <Input
                                                    // placeholder="passport No"
                                                    size="large"
                                                    className="inputbg"
                                                    placeholder="Passport Number"
                                                  />
                                                </Form.Item>
                                              </Col>
                                            )}
                                          {totalPaxArr[index].hasOwnProperty(
                                            "passportDOI"
                                          ) && (
                                              <Col md={6} sm={8} xs={24}>
                                                <Form.Item
                                                  name={[index, "passportDOI"]}
                                                  label="Passport DOI"
                                                  className="passport-dates"
                                                  rules={[
                                                    {
                                                      required: true,
                                                      message: "Required",
                                                    },
                                                  ]}
                                                >
                                                  <DatePicker
                                                    placeholder="Issued Date"
                                                    size="large"
                                                    format={dateformat}
                                                    style={{ width: "100%" }}
                                                  />
                                                </Form.Item>
                                              </Col>
                                            )}
                                          {totalPaxArr[index].hasOwnProperty(
                                            "passportDOE"
                                          ) && (
                                              <Col md={6} sm={8} xs={24}>
                                                <Form.Item
                                                  name={[index, "passportDOE"]}
                                                  label="Passport DOE"
                                                  className="passport-dates"
                                                  rules={[
                                                    {
                                                      required: true,
                                                      message: "Required",
                                                    },
                                                  ]}
                                                >
                                                  <DatePicker
                                                    placeholder="Expiry Date"
                                                    format={dateformat}
                                                    // open={showFromDate}
                                                    style={{ width: "100%" }}
                                                    size="large"
                                                  />
                                                </Form.Item>
                                              </Col>
                                            )}
                                          {totalPaxArr[index].hasOwnProperty(
                                            "passportIssuedCountry"
                                          ) && (
                                              <Col md={6} sm={8} xs={24}>
                                                <Form.Item
                                                  name={[
                                                    index,
                                                    "passportIssuedCountry",
                                                  ]}
                                                  label="Passport Issued"
                                                  rules={[
                                                    {
                                                      required: true,
                                                      message: "Required",
                                                    },
                                                  ]}
                                                >
                                                  <Select
                                                    placeholder="Select Issued Country"
                                                    size="large"
                                                    showSearch
                                                    filterOption={(input, option) =>
                                                      option.children
                                                        .toLowerCase()
                                                        .indexOf(
                                                          input.toLowerCase()
                                                        ) >= 0
                                                    }
                                                  >
                                                    {CountryList.map((item) => (
                                                      <Option
                                                        key={item.name}
                                                        value={item.name}
                                                      >
                                                        {item.name}
                                                      </Option>
                                                    ))}
                                                  </Select>
                                                </Form.Item>
                                              </Col>
                                            )}
                                        </Row>
                                      </>
                                    ));
                                  }}
                                </Form.List>
                              </Form>
                            </>
                          ) : null}
                        </div>
                      </Card>
                    </div>
                  </div>
                  {isLoading ? (
                    <>

                      <Card className="checkout-custom-card">
                        <Skeleton active />
                      </Card>
                    </>
                  ) :
                    <div>
                      {/* {console.log(showFlightSSR, "show")} */}
                      {showFlightSSR ?
                        <Card className="flight-cards-details">
                          <div className="user-details1">
                            <p className="extraser">Add Extra Services</p>
                            {/* <Checkbox onChange={(e) => onCheckChange(e)}>
                              <p>To Add Extra Baggage, Meal Preference's and Additional Service's For your happy flight</p>
                            </Checkbox> */}
                            {showalldetails ?
                              <div className="ssr-div">
                                {showameal ?
                                  <div className="ssr-block" onClick={() => setmealVisible(true)}>
                                    <img src={admeal} alt="" />
                                    Add Meals
                                  </div> : null}
                                {showbagg ?
                                  <div className="ssr-block" onClick={() => setbaggageVisible(true)}>
                                    <img src={adbag} alt="" />
                                    Add Baggage
                                  </div> : null}
                                {showSeat ?
                                  <div className="ssr-block" onClick={() => setSeatVisible(true)}>
                                    <img style={{ margin: "8px 4px 11px" }} src={adair} alt="" />
                                    Seats
                                  </div> : null}

                              </div> :
                              <div className="ssr-div-loader">

                                <div className="ssr-block-l">
                                  <img src={admeal} alt="" />
                                  Add Meals
                                </div>

                                <div className="ssr-block-l">
                                  <img src={adbag} alt="" />
                                  Add Baggage
                                </div>

                                <div className="ssr-block-l" >
                                  <img style={{ margin: "8px 4px 11px" }} src={adair} alt="" />
                                  Seats
                                </div>

                              </div>}
                            <div className="inf-ssr">
                              <div className="ssr-inf-1"><i class="fa fa-info-circle" aria-hidden="true"></i> Info : </div>
                              <div className="ssr-inf-p">The Above Selected Meal are Subject to Availability</div>
                            </div>
                          </div>
                        </Card> : null}
                      <div className="check-in-Rules">
                        <div className="check-in-hdr">
                          <h6><span style={{ marginRight: "5px" }}>< LikeOutlined /></span>Good To Know</h6>
                          <p>Information you should Know</p>
                        </div>
                        <div className="check-in">
                          <p><span><i class="fa fa-hand-o-right" aria-hidden="true"></i></span>Remember to web check-in before arriving at the airport</p>
                          <p><span><i class="fa fa-hand-o-right" aria-hidden="true"></i></span>Face masks are advisable</p>
                          <p><span><i class="fa fa-hand-o-right" aria-hidden="true"></i></span>Reach Before Prior to Depaurter time, & go Tension free with Anjmal</p>

                        </div>
                      </div>
                    </div>
                  }
                  {/* <div className="user-details1 paxInfowrapper margin-contact">
                    {isLoading ? (
                      <>
                        <p className="heading">Passenger Details</p>
                        <Card className="checkout-custom-card">
                          <Skeleton active />
                        </Card>
                      </>
                    ) : totalPaxArr.length > 0 ? (
                      <>
                        <p className="heading">Passenger Details</p>

                        <Form
                          layout="vertical"
                          className="passenger-form user-details "
                          validateMessages={validateMessages}
                          form={passenger_form}
                          initialValues={{ passengers: totalPaxArr }}
                        >
                          <Card className="checkout-custom-card">
                            <Form.List name="passengers">
                              {(fields) => {
                                return fields.map((field, index) => (
                                  <>
                                    <Row gutter={[16, 8]}>
                                      <Col md={3} sm={8} xs={24}>
                                        <div className="pax-heading">
                                          {totalPaxArr[index].paxType === "ADT"
                                            ? "Adult"
                                            : totalPaxArr[index].paxType ===
                                              "CHD"
                                              ? "Child"
                                              : "Infant"}
                                        </div>
                                      </Col>
                                      {flag && (
                                        <>
                                          <Col md={13} sm={8} xs={24}>
                                            <div className="pax-head-hr">
                                              <hr />
                                            </div>
                                          </Col>

                                          <Col md={8} sm={8} xs={24}>
                                            <Select
                                              placeholder="Choose"
                                              onSelect={(val) => {
                                                handleTravelerDetails(
                                                  val,
                                                  index
                                                );
                                              }}
                                              style={{ width: "100%" }}
                                            >
                                              {travellersListResp.Travellers.map(
                                                (travelerName) => (
                                                  <Option
                                                    value={
                                                      travelerName.TravellerID
                                                    }
                                                    key={
                                                      travelerName.TravellerID
                                                    }
                                                  >
                                                    {travelerName.FirstName +
                                                      " " +
                                                      travelerName.LastName}
                                                  </Option>
                                                )
                                              )}
                                            </Select>
                                          </Col>
                                        </>
                                      )}
                                    </Row>
                                    <Row gutter={[16, 8]}>
                                      <Col md={8} sm={8} xs={24}>
                                        <Form.Item
                                          {...field}
                                          name={[index, "title"]}
                                          label="Title"
                                          rules={[
                                            {
                                              required: true,
                                              message: "Specify Title",
                                            },
                                          ]}
                                        >
                                          <Select
                                          size={"large"}
                                            placeholder="Title"
                                            rules={[
                                              {
                                                required: true,
                                                message: "Required",
                                              },
                                            ]}
                                          >
                                            {totalPaxArr[index].paxType ===
                                              "ADT" ? (
                                              <>
                                                <Select.Option value="MR">
                                                  Mr
                                                </Select.Option>
                                                <Select.Option value="MRS">
                                                  Mrs
                                                </Select.Option>
                                              </>
                                            ) : (
                                              <>
                                                <Select.Option value="MSTR">
                                                  Mstr
                                                </Select.Option>
                                              </>
                                            )}

                                            <Select.Option value="MS">
                                              Ms
                                            </Select.Option>
                                          </Select>
                                        </Form.Item>
                                      </Col>
                                      <Col md={8} sm={8} xs={24}>
                                        <Form.Item
                                          {...field}
                                          name={[index, "firstName"]}
                                          label="First Name"
                                          rules={[
                                            {
                                              required: true,
                                              message: "Required",
                                            },
                                            {
                                              pattern: "^[a-zA-Z ]*$",
                                              message:
                                                "Alphabet Characters Only",
                                            },
                                            {
                                              min: 2,
                                              message:
                                                "First Name must be minimum 2 characters.",
                                            },
                                            {
                                              max: 32,
                                              message:
                                                "First Name must be maximum 32 characters.",
                                            },
                                            ({ getFieldValue }) => ({
                                              validator(_, value) {
                                                if (
                                                  !value ||
                                                  getFieldValue([
                                                    "passengers",
                                                    index,
                                                    "lastName",
                                                  ]) != value
                                                ) {
                                                  if (
                                                    !abbrevations.includes(
                                                      value
                                                    )
                                                  ) {
                                                    return Promise.resolve();
                                                  } else {
                                                    return Promise.reject(
                                                      new Error(
                                                        "Abbrevations are not accepted in beginning."
                                                      )
                                                    );
                                                  }
                                                }
                                                return Promise.reject(
                                                  new Error(
                                                    "The First and Last Name should not be same."
                                                  )
                                                );
                                              },
                                            }),
                                          ]}
                                        >
                                          <Input className="inputbg"
                                            size={"large"}
                                            placeholder="Enter Your First Name" />
                                        </Form.Item>
                                      </Col>
                                      <Col md={8} sm={8} xs={24}>
                                        <Form.Item
                                          {...field}
                                          name={[index, "lastName"]}
                                          label="Last Name"
                                          rules={[
                                            {
                                              required: true,
                                              message: "Required",
                                            },
                                            {
                                              min: 2,
                                              message:
                                                "Last Name must be minimum 2 characters.",
                                            },
                                            {
                                              max: 32,
                                              message:
                                                "Last Name must be maximum 32 characters.",
                                            },
                                            {
                                              pattern: "^[a-zA-Z ]*$",
                                              message:
                                                "Alphabet Characters Only",
                                            },
                                            ({ getFieldValue }) => ({
                                              validator(_, value) {
                                                if (
                                                  !value ||
                                                  getFieldValue([
                                                    "passengers",
                                                    index,
                                                    "firstName",
                                                  ]) != value
                                                ) {
                                                  if (
                                                    !abbrevations.includes(
                                                      value
                                                    )
                                                  ) {
                                                    return Promise.resolve();
                                                  } else {
                                                    return Promise.reject(
                                                      new Error(
                                                        "Abbrevations are not accepted in beginning."
                                                      )
                                                    );
                                                  }
                                                }
                                                return Promise.reject(
                                                  new Error(
                                                    "The First and Last Name should not be same."
                                                  )
                                                );
                                              },
                                            }),
                                          ]}
                                        >
                                          <Input className="inputbg"
                                            size={"large"}
                                            placeholder="Enter Your Last Name" />
                                        </Form.Item>
                                      </Col>

                                      {totalPaxArr[index].hasOwnProperty(
                                        "dob"
                                      ) && (
                                          <Col md={8} sm={8} xs={24}>
                                            <Form.Item
                                              label="Date of Birth"
                                              name={[index, "dob"]}
                                              rules={[
                                                {
                                                  required: true,
                                                  message: "Required",
                                                },
                                                {
                                                  validator: (_, v) =>
                                                    PaxAgeValidator(
                                                      totalPaxArr[index].paxType,
                                                      v
                                                    ),
                                                },
                                              ]}
                                            >
                                              <DatePicker
                                                className="inputbg"
                                                allowClear={false}
                                                format={dateformat}
                                                style={{ width: "100%" }}
                                                disabledDate={disabledFutureDate}
                                                placeholder="DD-MM-YYYY"
                                                size={"large"}
                                              />
                                            </Form.Item>
                                          </Col>
                                        )}
                                      {totalPaxArr[index].hasOwnProperty("passengerNationality")
                                        && (
                                          <Col md={8} sm={8} xs={24}>
                                            <Form.Item
                                              {...field}
                                              name={[
                                                index,
                                                "passengerNationality",
                                              ]}
                                            
                                              label="Nationality"
                                              rules={[
                                                {
                                                  required: true,
                                                  message: "Required",
                                                },
                                                // {
                                                //   pattern: "^[a-zA-Z ]*$",
                                                //   message:
                                                //     "Alphabet Characters Only",
                                                // },
                                              ]}
                                            >
                                              
                                              {/* <Input className="inputbg" /> 
                                              <Select
                                                showSearch
                                                size={"large"}
                                                placeholder="Select Country"
                                                style={{ width: "100%" }}
                                                filterOption={(input, option) =>
                                                  option.children
                                                    .toLowerCase()
                                                    .indexOf(input.toLowerCase()) >= 0
                                                }
                                              >
                                                {CountryList.map((item) => (
                                                  <Option
                                                    key={item.name}
                                                    value={item.name}
                                                  >
                                                    {item.name}
                                                  </Option>
                                                ))}
                                              </Select>
                                            </Form.Item>
                                          </Col>
                                        )}
                                      {totalPaxArr[index].hasOwnProperty(
                                        "passportNumber"
                                      ) && (
                                          <Col md={8} sm={8} xs={24}>
                                            <Form.Item
                                              name={[index, "passportNumber"]}
                                              label="Passport Number"
                                              rules={[
                                                {
                                                  required: true,
                                                  message: "Required",
                                                },
                                              ]}
                                            >
                                              <Input
                                                className="inputbg"
                                                placeholder="Passport Number"
                                              />
                                            </Form.Item>
                                          </Col>
                                        )}
                                      {totalPaxArr[index].hasOwnProperty(
                                        "passportDOI"
                                      ) && (
                                          <Col md={8} sm={8} xs={24}>
                                            <Form.Item
                                              name={[index, "passportDOI"]}
                                              label="Passport DOI"
                                              className="passport-dates"
                                              rules={[
                                                {
                                                  required: true,
                                                  message: "Required",
                                                },
                                              ]}
                                            >
                                              <DatePicker
                                                format={dateformat}
                                                style={{ width: "100%" }}
                                              />
                                            </Form.Item>
                                          </Col>
                                        )}
                                      {totalPaxArr[index].hasOwnProperty(
                                        "passportDOE"
                                      ) && (
                                          <Col md={8} sm={8} xs={24}>
                                            <Form.Item
                                              name={[index, "passportDOE"]}
                                              label="Passport DOE"
                                              className="passport-dates"
                                              rules={[
                                                {
                                                  required: true,
                                                  message: "Required",
                                                },
                                              ]}
                                            >
                                              <DatePicker
                                                format={dateformat}
                                                style={{ width: "100%" }}
                                              />
                                            </Form.Item>
                                          </Col>
                                        )}
                                      {totalPaxArr[index].hasOwnProperty(
                                        "passportIssuedCountry"
                                      ) && (
                                          <Col md={8} sm={8} xs={24}>
                                            <Form.Item
                                              name={[
                                                index,
                                                "passportIssuedCountry",
                                              ]}
                                              label="Passport Issue Country"
                                              rules={[
                                                {
                                                  required: true,
                                                  message: "Required",
                                                },
                                              ]}
                                            >
                                              <Select
                                                showSearch
                                                placeholder="Select Passport Issued Country"
                                                filterOption={(input, option) =>
                                                  option.children
                                                    .toLowerCase()
                                                    .indexOf(
                                                      input.toLowerCase()
                                                    ) >= 0
                                                }
                                              >
                                                {CountryList.map((item) => (
                                                  <Option
                                                    key={item.name}
                                                    value={item.name}
                                                  >
                                                    {item.name}
                                                  </Option>
                                                ))}
                                              </Select>
                                            </Form.Item>
                                          </Col>
                                        )}
                                    </Row>
                                  </>
                                ));
                              }}
                            </Form.List>
                          </Card>

                          {/* {insuranceData.status === 1 &&
                            insuranceData?.serviceType === 1 ? (
                            <Card className="insurance-section">
                              <h5>THE SMART INSURANCE COVER</h5>
                              <p>EASY CLAIM PROCESS | NO QUESTIONS ASKED</p>

                              <Row
                                gutter={[16, 16]}
                                align="stretch"
                                className="mt-4"
                              >
                                <Col md={8} sm={24} xs={24}>
                                  <div className="insurance-box">
                                    {insuranceData.description_box_1}
                                  </div>
                                </Col>
                                <Col md={8} sm={24} xs={24}>
                                  <div className="insurance-box">
                                    {insuranceData.description_box_2}
                                  </div>
                                </Col>
                                <Col md={8} sm={24} xs={24}>
                                  <div className="insurance-box">
                                    {insuranceData.description_box_3}
                                  </div>
                                </Col>
                              </Row>

                              <div className="insurance-coverage">
                                <SafetyCertificateOutlined />
                                <span>
                                  Insurance Coverage Amount :{" "}
                                  {insuranceData.insuranceCoverage}
                                </span>
                              </div>

                              <Form.Item
                                name="insuranceRequired"
                                rules={[
                                  {
                                    required: true,
                                    message: "Please Select Insurance",
                                  },
                                ]}
                              >
                                <Radio.Group
                                  buttonStyle="solid"
                                  onChange={(e) =>
                                    handleInsuranceChange(e.target.value)
                                  }
                                  className="insurance-radio-wrapper"
                                >
                                  <Row gutter={[16, 16]}>
                                    <Col md={10} sm={24} xs={24}>
                                      <Radio
                                        className={`radio-btn btn-insure  ${insuranceRequired === 1
                                          ? "btn-insure-active"
                                          : ""
                                          }`}
                                        value={1}
                                      >
                                        Insure For ₹{insuranceData.amount}/pax
                                      </Radio>
                                    </Col>

                                    <Col md={10} sm={24} xs={24}>
                                      <Radio
                                        value={0}
                                        className={`radio-btn btn-risk ${insuranceRequired === 0
                                          ? "btn-risk-active"
                                          : ""
                                          }`}
                                      >
                                        I'll Risk it
                                      </Radio>
                                    </Col>
                                  </Row>
                                </Radio.Group>
                              </Form.Item>
                            </Card>
                          ) : null} *
                              </Form>
                            </>
                          ) : null}
                        </div> */}

                  {/* <Form
                    layout="vertical"
                    className="passenger-form"
                    form={gst_form}
                  >
                    {isGstRequired ? (
                      <div className="user-details gst-details margin-contact">
                        <p className="heading">GST Details</p>

                        <Card className="gst-custom-details flight-cards-details">
                          <Row gutter={[16, 8]}>
                            <Col md={8} sm={8} xs={24}>
                              <Form.Item
                                label="Company Name"
                                name="gstCompanyName"
                                rules={[
                                  {
                                    required: true,
                                    message: "Required",
                                  },
                                ]}
                              >
                                <Input className="contacts-value-de" />
                              </Form.Item>
                            </Col>
                            <Col md={8} sm={8} xs={24}>
                              <Form.Item
                                label="GST Number"
                                name="gstNumber"
                                rules={[
                                  {
                                    required: true,
                                    message: "Required",
                                  },
                                  {
                                    pattern:
                                      "^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$",

                                    message: "Please Enter a Valid GST Number",
                                  },
                                ]}
                              >
                                <Input
                                  className="contacts-value-de"
                                  placeholder="GST Number"
                                />
                              </Form.Item>
                            </Col>
                            <Col md={8} sm={8} xs={24}>
                              <Form.Item
                                label="Contact Number"
                                name="gstPhoneNo"
                                rules={[
                                  {
                                    required: true,
                                    message: "Required",
                                  },
                                ]}
                              >
                                <Input
                                  className="contacts-value-de"
                                  placeholder="Contact Number"
                                />
                              </Form.Item>
                            </Col>
                            <Col md={8} sm={8} xs={24}>
                              <Form.Item
                                label="Company Email"
                                name="gstEmailId"
                                rules={[
                                  {
                                    required: true,
                                    message: "Required",
                                  },
                                ]}
                              >
                                <Input
                                  className="contacts-value-de"
                                  placeholder="Company Email"
                                />
                              </Form.Item>
                            </Col>
                            <Col md={8} sm={8} xs={24}>
                              <Form.Item
                                label="Company Address"
                                name="gstAddressLine1"
                                rules={[
                                  {
                                    required: true,
                                    message: "Required",
                                  },
                                ]}
                              >
                                <Input
                                  className="contacts-value-de"
                                  placeholder="Company Address"
                                />
                              </Form.Item>
                            </Col>
                          </Row>
                        </Card>
                      </div>
                    ) : null}
                  </Form> */}
                </Col>
                <Col className="checkout-top-part">
                  <Card className="checkout-sticky-part">
                    <p className="heading-3">Fare Details</p>
                    {isLoading ? (
                      <>
                        <div className="sticky-card-container">
                          <Card className="pax-count-card flight-cards-details">
                            <Skeleton active />
                          </Card>
                        </div>
                        <div className="grand-total-card">
                          <Card className="flight-cards-details">
                            <Skeleton active />
                          </Card>
                        </div>
                      </>
                    ) : (airPriceResp && (
                      <>
                        {(airPriceResp?.flightDetails.length === 1 || airPriceResp?.mappingType?.toUpperCase() === "COMBINED") ?
                          <>
                            {airPriceResp?.searchRequest?.airTravelType === "multidestination" ? <>
                              {/* {airPriceResp.flightDetails.map((flightDetObj, i) => ( */}
                              <FareSummaryCard
                                flightDetObj={airPriceResp.flightDetails[0]}
                                flightSearchObj={flightSearchObj}
                                // mealdetails={mealPlanPaxArr}
                                // baggagedetails={baggagePlanArr}
                                title={

                                  "Trip Fare"

                                }
                                index={"0"}
                              />
                              {/* ))
                            } */}
                            </> : <>
                              {airPriceResp.flightDetails.map((flightDetObj, i) => (
                                <FareSummaryCard
                                  flightDetObj={flightDetObj}
                                  flightSearchObj={flightSearchObj}
                                  // mealdetails={mealPlanPaxArr}
                                  // baggagedetails={baggagePlanArr}
                                  title={
                                    i === 0
                                      ? "Trip Fare"
                                      : "Fare"
                                  }
                                  index={i}
                                />
                              ))
                              }
                            </>
                            }

                          </> :
                          <>
                            {airPriceResp?.flightDetails.map((flightDetObj, i) => (
                              <FareSummaryCard
                                flightDetObj={flightDetObj}
                                flightSearchObj={flightSearchObj}
                                // mealdetails={mealPlanPaxArr}
                                // baggagedetails={baggagePlanArr}
                                title={
                                  i === 0
                                    ? "Onward Trip Fare"
                                    : "Return Trip Fare"
                                }
                                index={i}
                              />
                            ))
                            }
                          </>
                        }
                        {airPriceResp?.flightDetails?.length > 0 ? (
                          <div className="grand-total-card">
                            <FlightGrandTotalCard
                              airPriceResp={airPriceResp}
                              flightSearchObj={flightSearchObj}
                              //mealdetails={mealPlanPaxArr}
                              mealdetails={mealData}
                              baggagedetails={baggData}
                              location={location}
                              seatdetails={seatData}
                            />
                          </div>
                        ) : null}
                      </>
                    )
                    )}
                  </Card>
                </Col>
              </Row>

              <Row gutter={16}>
                <Col sm={24} md={16}>
                  <BookPayCard
                    isLoading={isLoading}
                    bookpaycardinfo={"flight-checkout"}
                    redirectToPreview={redirectToPreview}
                  />
                </Col>
              </Row>
            </div>
          </section>
        </div >
      </div >
      <Modal
        title="Meal Prefarence"
        className="promo-modal-header modal-header-bg12"
        visible={mealVisible}
        // onOk={(e) => setmealVisible(false)}
        onCancel={(e) => setmealVisible(false)}
        footer={[
          <div className="mdl-footer">
            {showbagg && (
              <div className="ssr-div">
                <div className="ssr-block" onClick={() => onChangeModal('baggage')}>
                  <img src={adbag} alt="" />
                  Add Baggage
                </div>
              </div>
            )}
            {showSeat && (
              <div className="ssr-div">
                <div className="ssr-block" onClick={() => onChangeModal('seat')}>
                  <img src={adair} alt="" />
                  Seat Layout
                </div>
              </div>
            )}
            <div className="btn">
              <Button type="primary" onClick={() => setmealVisible(false)}>
                OK
              </Button>
            </div>
          </div>,
        ]}

        width={"1100px"}
      >

        <div className="ssr-body">
          {ssrResponse?.specialServiceRequest?.map((flight, id) => {
            return (
              <div className="ssr-part">

                <div className="hdr-type">
                  <div className="ssr-hdr">
                    Travellers
                  </div>
                  {getHeader(ssrResponse?.specialServiceRequest?.[id]?.MEAL)?.map((e, index) => {
                    return (
                      <>
                        <div className="seg-ssr" onClick={() => { }}>{e} <span className="fli-ssr"><img src={fliy} alt="" /></span></div>
                      </>)
                  })
                  }
                </div>

                <div className="ssr-pax-hdr">{mealPlanPaxArr?.map((pax, i) => {

                  return (
                    <>
                      <div className="ssr-pax-block">
                        <div className="ssr-pax-type">
                          {pax?.paxType == "ADT" ? "Adult " + (i + 1) : "Child " + (i + 1)}
                        </div>
                        {pax?.paxType !== "INF" && (
                          <>
                            {getHeader(ssrResponse?.specialServiceRequest?.[id]?.MEAL)?.map((e, index) => {
                              return (<><div className="seg-ssr-part" onClick={() => { setactiveMeal([id, i]); setactiveMealSegment(e) }}>{activeMeal[0] == id && activeMeal[1] == i && activeMealSegment == e ? "-" : "+ "}Add Meals </div>
                              </>)
                            })
                            }
                          </>
                        )}
                      </div>
                      {pax?.paxType !== "INF" && activeMeal[0] == id && activeMeal[1] == i && <section className="carousel-section desktop_crou">
                        <div className="ssr-b">

                          <Slider {...settingsMeal}>{ssrResponse?.specialServiceRequest?.[id]?.MEAL?.map((meal, idx) => {
                            // return (<>{(meal?.code != "NoMeal" && (activeMealSegment == meal?.cityPair || meal?.cityPair == "undefined-undefined")) &&
                            //   <div className="ssr-a">
                            //     <div className="image-container">
                            return (<>{(meal?.code != "NoMeal") &&
                              <div className="ssr-a">
                                {activeMealSegment == meal?.cityPair ?
                                  <div className="image-container">
                                    <img className="ssr-img" src={ssrmeal} alt="" />
                                    <div className={(mealData[id]?.[GetIndex(meal?.cityPair)]?.[i]?.mealCode === meal.code && pax?.mealplancode !== "NoMeal") && madd ? "rem-meal" : "overlay"}>
                                      <div className="ovr-rem-sssr" onClick={() => getmealcode(meal, i, pax?.paxType + i, "remove")}>
                                        {(mealData[id]?.[GetIndex(meal?.cityPair)]?.[i]?.mealCode === meal.code && pax?.mealplancode != "NoMeal") && madd ?
                                          <span className="rem-ssr"> Remove <CloseCircleOutlined /></span> : null}
                                      </div>
                                      <span className="pr-ssr-tag">{activeCurrency} {currencyValue(meal.amount)}</span>

                                      <div className="ssr-c">
                                        <p className="desc-ssr">{meal?.airlineDesc != null ? meal?.airlineDesc : meal?.desc}</p>
                                        <div className="but-sel" onClick={() => getmealcode(meal, i, pax?.paxType + i, "add")}>{((mealData[id]?.
                                        [GetIndex(meal?.cityPair)]?.[i]?.mealCode === meal.code && pax?.mealplancode != "NoMeal") && madd ? "Added" : "ADD")}
                                        </div>
                                        {/* <div className="but-sel" onClick={() => getmealcode(meal, i, pax?.paxType + i, "add")}>
                                          {((mealData[id]?.[GetIndex(meal?.cityPair)]?.[i]?.mealCode === meal.code && pax?.mealplancode != "NoMeal") && madd ? "Added" : "ADD")}
                                          {(mealData[id]?.[GetIndex(meal?.cityPair)]?.[i]?.mealCode === meal.code && pax?.mealplancode != "NoMeal") && madd ?
                                            <span ><CloseCircleOutlined onClick={() => getmealcode(meal, i, pax?.paxType + i, "remove")} /></span> : null}
                                        </div> */}
                                      </div>
                                    </div>
                                  </div> :
                                  <div className="image-container">

                                    <img className="ssr-img" src={ssrmeal} alt="" />

                                    <div className={(mealData[id]?.[GetIndex(meal?.cityPair)]?.[i]?.mealCode === meal.code && pax?.mealplancode !== "NoMeal") && madd ? "overlay" : "rem-meal"}>


                                      <span className="pr-ssr-tag">{meal?.cityPair}</span>
                                      {/* {console.log("venu", meal?.code == pax?.mealplancode)} */}
                                      <div className="ssr-c">
                                        {(mealData[id]?.[GetIndex(meal?.cityPair)]?.[i]?.mealCode === meal.code && pax?.mealplancode !== "NoMeal") && madd &&
                                          <p className="desc-ssr">{meal?.airlineDesc != null ? meal?.airlineDesc : meal?.desc}</p>}
                                        {/* <div className="but-sel" onClick={() => getmealcode(meal, i, pax?.paxType + i, "add")}>{((mealData[id]?.
                                          [GetIndex(meal?.cityPair)]?.[i]?.mealCode === meal.code && pax?.mealplancode != "NoMeal") && madd ? "Added" : "ADD")}

                                          </div> */}
                                      </div>

                                    </div>

                                  </div>}
                              </div>

                            }</>)
                          })}
                          </Slider>
                        </div>
                      </section>}
                      <section className="carousel-section mobile_crou">
                        <div className="ssr-b">

                          <Slider {...MobileSlidersettings}>{ssrResponse?.specialServiceRequest?.[id]?.MEAL?.map((meal) => {
                            return (<>{meal?.code != "NoMeal" &&
                              <div className="ssr-a">
                                <div className="image-container">
                                  <img className="ssr-img" src={ssrmeal} alt="" />
                                  <div className={(mealData[id]?.[GetIndex(meal?.cityPair)]?.[i]?.mealCode === meal.code && pax?.mealplancode !== "NoMeal") && madd ? "rem-meal" : "overlay"}>
                                    <div className="ovr-rem-sssr" onClick={() => getmealcode(meal, i, pax?.paxType + i, "remove")}>
                                      {(mealData[id]?.[GetIndex(meal?.cityPair)]?.[i]?.mealCode === meal.code && pax?.mealplancode != "NoMeal") && madd ?
                                        <span className="rem-ssr"> Remove <CloseCircleOutlined /></span> : null}
                                    </div>
                                    <span className="pr-ssr-tag">{activeCurrency} {currencyValue(meal.amount)}</span>
                                    {/* {console.log("venu", meal?.code == pax?.mealplancode)} */}
                                    <div className="ssr-c">
                                      <p className="desc-ssr">{meal?.airlineDesc}</p>
                                      <div className="but-sel" onClick={() => getmealcode(meal, i, pax?.paxType + i, "add")}>{((mealData[id]?.
                                      [GetIndex(meal?.cityPair)]?.[i]?.mealCode === meal.code && pax?.mealplancode != "NoMeal") && madd ? "Added" : "ADD")}
                                        {/* {meal?.code == pax?.mealplancode ?
                                    <span><CheckCircleOutlined /></span> : null} */}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                            }</>)
                          })}
                          </Slider>
                        </div>
                      </section>
                    </>
                  )
                })}
                </div>
              </div>)
          })}

        </div>
      </Modal>
      <Modal
        title="Extra Baggage"
        className="promo-modal-header modal-header-bg12"
        visible={baggageVisible}
        // onOk={(e) => setbaggageVisible(false)}
        onCancel={(e) => setbaggageVisible(false)}
        // footer={[
        //   <div className="mdl-footer">
        //     {showameal ?
        //       <div className="ssr-div">
        //         <div className="ssr-block" onClick={() => onChangeModal()}>
        //           <img src={admeal} alt="" />
        //           Add Meals
        //         </div>
        //       </div> : null}
        //     {showSeat ?
        //       <div className="ssr-div">
        //         <div className="ssr-block" onClick={() => onChangeModal()}>
        //           <img src={admeal} alt="" />
        //           Seat Layout
        //         </div>
        //       </div> : null}
        //     <div className="btn">
        //       {/* <Button
        //         type="primary"
        //         danger
        //         className="cancel-btn"
        //         onClick={(e) => {
        //           setbaggageVisible(false);
        //         }}
        //       >
        //         Cancel
        //       </Button> */}
        //       <Button
        //         type="primary"
        //         onClick={() => {
        //           setbaggageVisible(false);
        //           // setFormType(1);
        //         }}
        //       >
        //         OK
        //         {/* {isEditMode ? "Update" : "Add"} */}
        //       </Button>

        //     </div>
        //   </div>,
        // ]}
        footer={[
          <div className="mdl-footer">
            {showameal && (
              <div className="ssr-div">
                <div className="ssr-block" onClick={() => onChangeModal('meal')}>
                  <img src={admeal} alt="" />
                  Add Meals
                </div>
              </div>
            )}
            {showSeat && (
              <div className="ssr-div">
                <div className="ssr-block" onClick={() => onChangeModal('seat')}>
                  <img src={adair} alt="" />
                  Seat Layout
                </div>
              </div>
            )}
            <div className="btn">
              <Button type="primary" onClick={() => setbaggageVisible(false)}>
                OK
              </Button>
            </div>
          </div>,
        ]}
        width={"1100px"}
      >

        <div className="ssr-body">
          {ssrResponse?.specialServiceRequest?.map((flight, id) => {
            return (
              <>
                {ssrResponse?.specialServiceRequest?.[id]?.BAGGAGE?.length > 0 ?
                  <div className="ssr-part">

                    <div className="hdr-type">
                      <div className="ssr-hdr">
                        Travellers
                      </div>
                      {getHeader(ssrResponse?.specialServiceRequest?.[id]?.BAGGAGE)?.map((e, index) => {
                        return (
                          <>
                            <div className="seg-ssr" onClick={() => { }}>{e} <span className="fli-ssr"><img src={fliy} alt="" /></span></div>
                          </>)
                      })
                      }
                    </div>

                    <div className="ssr-pax-hdr">{baggagePlanArr?.map((pax, i) => {
                      //  console.log(pax,"pax for me")
                      return (
                        <>
                          { }
                          <div className="ssr-pax-block">
                            <div className="ssr-pax-type">
                              {/* {pax?.paxType == "ADT" ? "Adult " + (i + 1) : "Child " + (i + 1)} */}
                              {pax?.paxType === "ADT" ? "Adult " + (i + 1) : pax?.paxType === "CHD" && pax?.paxType !== "INF" ? "Child " + (i + 1) : ""}

                            </div>
                            {pax?.paxType !== "INF" && (
                              <>
                                {getHeader(ssrResponse?.specialServiceRequest?.[id]?.BAGGAGE)?.map((e, index) => {
                                  return (<><div className="seg-ssr-part" onClick={() => { setactivebagg([id, i]); setactivebaggSegment(e) }}>{activebagg[0] == id && activebagg[1] == i && activebaggSegment == e ? "-" : "+ "}Add Baggage </div>
                                  </>)
                                })
                                }
                              </>
                            )}
                          </div>
                          {pax?.paxType !== "INF" && activebagg[0] == id && activebagg[1] == i && <section className="carousel-section desktop_crou">
                            <div className="ssr-b">

                              <Slider {...settings}>{ssrResponse?.specialServiceRequest?.[id]?.BAGGAGE?.map((bagg, idx) => {
                                return (<>{(bagg?.code != "NoBaggage" && (activebaggSegment == bagg?.cityPair || bagg?.cityPair == "undefined-undefined")) &&
                                  <div className="ssr-a">
                                    <div className="image-container">
                                      <img className="ssr-img" src={ssrbag} alt="" />
                                      <div className={(baggData[id]?.[GetIndx(bagg?.cityPair)]?.[i]?.baggCode === bagg.code && pax?.baggplancode != "NoBaggage") && badd ? "rem-bagg" : "overlay"}>
                                        <div className="ovr-rem-sssr" onClick={() => getbaggcode(bagg, i, pax?.paxType + i, "remove")}>
                                          {(baggData[id]?.[GetIndx(bagg?.cityPair)]?.[i]?.baggCode === bagg.code && pax?.baggplancode != "NoBaggage") && badd ?
                                            <span className="rem-ssr"> Remove <CloseCircleOutlined /></span> : null}
                                        </div>
                                        <span className="pr-ssr-tag">{activeCurrency} {currencyValue(bagg.amount)}</span>
                                        {/* {console.log("venu", meal?.code == pax?.mealplancode)} */}
                                        <div className="ssr-c">
                                          {/* <p className="desc-ssr">{bagg?.airlineDesc != null ? bagg?.airlineDesc : bagg?.desc}</p>{console.log("ven", baggData, i, id, idx, GetIndx(bagg?.cityPair))} */}
                                          <p className="desc-ssr">{bagg?.code != null ? (bagg?.weight + " Kgs") : bagg?.code}</p>
                                          {ssrResponse?.specialServiceRequest?.[id]?.supplier === "AKBAR" ? (
                                            <div className="but-sel" onClick={() => getbaggcode(bagg, i, pax?.paxType + i, "add")}>
                                              {bagg.amount > 0 ? (
                                                (baggData[id]?.[GetIndx(bagg?.cityPair)]?.[i]?.baggCode === bagg.code && pax?.baggplancode != "NoBaggage") && badd
                                                  ? "Added"
                                                  : "ADD"
                                              ) : null}
                                            </div>) : (

                                            <div className="but-sel" onClick={() => getbaggcode(bagg, i, pax?.paxType + i, "add")}>{((baggData[id]?.[GetIndx(bagg?.cityPair)]?.[i]?.baggCode === bagg.code && pax?.baggplancode != "NoBaggage") && badd ? "Added" : "ADD")}
                                              {/* <div className="but-sel" onClick={() => getbaggcode(bagg, i, pax?.paxType + i, "add")}>{((baggData[id]?.[GetIndx(bagg?.cityPair)]?.[i]?.baggCode === bagg.code && pax?.baggplancode != "NoBaggage") && badd ? "Added" : "ADD")}
                                            {(baggData[id]?.[GetIndx(bagg?.cityPair)]?.[i]?.baggCode === bagg.code && pax?.baggplancode != "NoBaggage") && badd ?
                                              <span><CloseCircleOutlined onClick={() => getbaggcode(bagg, i, pax?.paxType + i, "remove")} /></span> : null}
                                          </div> */}
                                            </div>)}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                }</>)
                              })}
                              </Slider >
                            </div>
                          </section>}
                          <section className="carousel-section mobile_crou">
                            <div className="ssr-b">

                              <Slider {...MobileSlidersettings}>{ssrResponse?.specialServiceRequest?.[id]?.BAGGAGE?.map((bagg) => {
                                return (<>{bagg?.code != "NoBaggage" &&
                                  <div className="ssr-a">
                                    <div className="image-container">
                                      <img className="ssr-img" src={ssrbag} alt="" />
                                      <div className={(baggData[id]?.[GetIndx(bagg?.cityPair)]?.[i]?.baggCode === bagg.code && pax?.baggplancode != "NoBaggage") && badd ? "rem-bagg" : "overlay"}>
                                        <div className="ovr-rem-sssr" onClick={() => getbaggcode(bagg, i, pax?.paxType + i, "remove")}>
                                          {(baggData[id]?.[GetIndx(bagg?.cityPair)]?.[i]?.baggCode === bagg.code && pax?.baggplancode != "NoBaggage") && badd ?
                                            <span className="rem-ssr"> Remove <CloseCircleOutlined /></span> : null}
                                        </div>
                                        <span className="pr-ssr-tag">{activeCurrency} {currencyValue(bagg.amount)}</span>
                                        {/* {console.log("venu", meal?.code == pax?.mealplancode)} */}
                                        <div className="ssr-c">
                                          <p className="desc-ssr">{bagg?.weight + " Kgs"}</p>
                                          {ssrResponse?.specialServiceRequest?.[id]?.supplier === "AKBAR" ? (
                                            <div className="but-sel" onClick={() => getbaggcode(bagg, i, pax?.paxType + i, "add")}>
                                              {bagg.amount > 0 ? (
                                                (baggData[id]?.[GetIndx(bagg?.cityPair)]?.[i]?.baggCode === bagg.code && pax?.baggplancode != "NoBaggage") && badd
                                                  ? "Added"
                                                  : "ADD"

                                              ) : null}
                                            </div>) : (


                                            <div className="but-sel" onClick={() => getbaggcode(bagg, i)}>{(bagg.code == bcode) && badd ? "Added" : "ADD"}
                                              {/* {meal?.code == pax?.mealplancode ?
                                    <span><CheckCircleOutlined /></span> : null} */}
                                            </div>)}
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                }</>)
                              })}
                              </Slider>
                            </div>
                          </section>
                        </>
                      )
                    })}
                    </div>
                  </div> : null}
              </>)
          })}

          {/* <Collapse bordered={true} accordion destroyInactivePanel={true}  >
            {baggagePlanArr?.map((pax, i) => {
              return (
                <Panel showArrow={true} collapsible="true" header={<div className="hdr-type">{pax?.paxType == "ADT" ? "Adult " + (i + 1) : "Child " + (i + 1)}</div>}>
                  <section className="carousel-section desktop_crou">
                    <div className="ssr-b">
                      <Slider {...settings}>{airPriceResp?.specialServiceRequest?.[0]?.BAGGAGE?.map((baggage) => {
                        return (<>{baggage?.code != "NoBaggage" &&
                          <div className="ssr-a">
                            <div className="image-container">
                              <img className="ssr-img" src={ssrbag} alt="" />
                              <div className="overlay">
                                <span>{activeCurrency} {currencyValue(baggage.amount)}</span>
                                {/* {console.log("venu", meal?.code == pax?.mealplancode)} *
                                <div className="ssr-c">
                                  <p className="desc-ssr">{baggage?.weight} Kgs</p>
                                  <div className="but-sel" onClick={() => getbaggagecode(baggage, i)}>{(baggage?.code == code && (pax?.baggagePlancode != "NoBagge" && i == indx)) && add ? "Added" : "ADD"}
                                    {/* {baggage?.code == pax?.baggageplancode ?
                                    <span><CheckCircleOutlined /></span> : null} *
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        }</>)
                      })}
                      </Slider>
                    </div>
                  </section>
                  <section className="carousel-section mobile_crou">
                    <div className="ssr-b">
                      <Slider {...MobileSlidersettings}>{airPriceResp?.specialServiceRequest?.[0]?.BAGGAGE?.map((baggage) => {
                        return (<>{baggage?.code != "NoBaggage" &&
                          <div className="ssr-a">
                            <div className="image-container">
                              <img className="ssr-img" src={ssrbag} alt="" />
                              <div className="overlay">
                                <span>{activeCurrency} {currencyValue(baggage.amount)}</span>
                                {/* {console.log("venu", meal?.code == pax?.mealplancode)} *
                                <div className="ssr-c">
                                  <p className="desc-ssr">{baggage?.weight} Kgs</p>
                                  <div className="but-sel" onClick={() => getbaggagecode(baggage, i)}>{(baggage?.code == code) && add ? "Added" : "ADD"} {baggage?.code == pax?.baggageplancode ?
                                    <span><CheckCircleOutlined /></span> : null}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        }</>)
                      })}
                      </Slider>
                    </div>
                  </section>
                </Panel>
              )
            })}
          </Collapse> */}
          {/* <div className="ssr-div">
            <div className="ssr-block" onClick={() => onChangeModal()}>
              <img src={admeal} alt="" />
              Add Meals
            </div>
          </div> */}
        </div>
      </Modal>
      <Modal
        title="SEAT LAYOUT"
        className="promo-modal-header modal-header-bg12"
        visible={seatVisible}
        // onOk={(e) => setbaggageVisible(false)}
        onCancel={(e) => setSeatVisible(false)}
        // footer={[
        //   <div className="mdl-footer">
        //     {showameal ?
        //       <div className="ssr-div">
        //         <div className="ssr-block" onClick={() => onChangeModal()}>
        //           <img src={admeal} alt="" />
        //           Add Meals
        //         </div>
        //       </div> : null}
        //     {showbagg ?
        //       <div className="ssr-div">
        //         <div className="ssr-block" onClick={() => onChangeModal()}>
        //           <img src={adbag} alt="" />
        //           Add Baggage
        //         </div>
        //       </div> : null}
        //     <div className="btn">

        //       <Button
        //         type="primary"
        //         onClick={() => {
        //           setSeatVisible(false);

        //         }}
        //       >
        //         OK

        //       </Button>

        //     </div>
        //   </div>,
        // ]}
        footer={[
          <div className="mdl-footer">
            {showameal && (
              <div className="ssr-div">
                <div className="ssr-block" onClick={() => onChangeModal('meal')}>
                  <img src={admeal} alt="" />
                  Add Meals
                </div>
              </div>
            )}
            {showbagg && (
              <div className="ssr-div">
                <div className="ssr-block" onClick={() => onChangeModal('baggage')}>
                  <img src={adbag} alt="" />
                  Add Baggage
                </div>
              </div>
            )}
            <div className="btn">
              <Button type="primary" onClick={() => setSeatVisible(false)}>
                OK
              </Button>
            </div>
          </div>,
        ]}
        width={"1100px"}
      >
        <div>
          <h4>Pax Details</h4>
          {ssrResponse?.specialServiceRequest?.map((flights, id) => (
            <div className="seatshow" key={`flight_${id}`}>

              <div className="passenger-pax-details">
                {/* {console.log(seatPlanpaxArr, "seaa")} */}
                {seatPlanpaxArr.map((passenger, index) => (<>
                  {passenger.paxType !== 'INF' && (
                    <div key={`passenger_${index}`} onClick={() => handlePassengerSelection(index, passenger.paxType)} className="passenger-section">
                      {passenger?.paxType === "ADT" ?
                        <h6><i class="fa fa-user" aria-hidden="true"></i> {" "}
                          {"Adult"} {index + 1}</h6> :
                        <h6><i class="fa fa-child" aria-hidden="true"></i> {" "}{"Child"} {index + 1}</h6>}
                      {/* <h6><i class="fa fa-child" aria-hidden="true"></i> {" "}{passenger?.paxType} {index + 1}</h6>} */}
                      <div className="city-seat-lay">
                        {ssrResponse?.specialServiceRequest?.[id]?.SEATDYNAMIC?.[0]?.SSRSEATS?.map((seat, a) => {
                          if (seat?.SegmentSeat?.length > 0) {
                            // { console.log(passenger, seat, "passs") }
                            return seat.SegmentSeat.map((segment, b) => {
                              if (segment?.RowSeats?.length > 1) {
                                return (
                                  <div key={`flight_${id}_${a}_${b}`} className="flight-segment">

                                    <button
                                      className="origin-destination-btn"
                                      style={{ display: "grid" }}
                                      onClick={() => {
                                        handleOriginDestinationClick(
                                          segment.RowSeats[1].Seats[0].origin,
                                          segment.RowSeats[1].Seats[0].destination
                                        );
                                        setShowFlightLayout(true);
                                      }}
                                    >

                                      <span>{segment.RowSeats[1].Seats[0].origin} - {segment.RowSeats[1].Seats[0].destination}</span>
                                      <span>{"- "}{seatData[id]?.[b]?.[index]?.seatCode ?? "N/a"}{" -"}</span>
                                      {seatData[id]?.[b]?.[index]?.amount > 0 ?
                                        <span>{activeCurrency} {currencyValue(seatData[id]?.[b]?.[index]?.amount)}</span> :
                                        <span>{"0.00"}</span>}
                                    </button>
                                    {/* {console.log("id", id, "index", index, b, "indexess")} */}
                                  </div>
                                );
                              }
                            })


                          }
                        })}
                      </div>

                    </div>
                  )}
                </>
                ))}
                {ssrResponse?.specialServiceRequest?.[id]?.supplier === "TBO" ?
                  <div className="seattypes">
                    <h6>   {" "}Seat Types</h6>
                    <div className="seat-desc">
                      <div className="seat-category">
                        <div className="seat-color" style={{ backgroundColor: 'red' }}></div>
                        <span>Premium Seat</span>
                        {/* <span>$$$</span> */}
                      </div>
                      <div className="seat-category">
                        <div className="seat-color" style={{ backgroundColor: 'blue' }}></div>
                        <span>Standard Seat</span>
                        {/* <span>$</span> */}
                      </div>
                      <div className="seat-category">
                        <div className="seat-color" style={{ backgroundColor: 'green' }}></div>
                        <span>Selected Seat</span>
                        {/* <span>$$</span> */}
                      </div>
                      <div className="seat-category">
                        <div className="seat-color" style={{ backgroundColor: 'gray' }}></div>
                        <span>Occupied Seat</span>
                        {/* <span>$$</span> */}
                      </div>
                    </div>
                  </div> :
                  <div className="seattypes">
                    <h6>   {" "}Seat Types</h6>
                    <div className="seat-desc">
                      <div className="seat-category">
                        <div className="seat-color" style={{ backgroundColor: 'red' }}></div>
                        <span>Spicemax</span>
                        <Popover content="Spicemax seats offer extra legroom and priority boarding.">
                          <InfoCircleOutlined style={{ marginLeft: 8, cursor: 'pointer' }} />
                        </Popover>
                      </div>
                      <div className="seat-category">
                        <div className="seat-color" style={{ backgroundColor: 'blue' }}></div>
                        <span>Preferred</span>
                        <Popover content="Preferred seats offer convenient access.">
                          <InfoCircleOutlined style={{ marginLeft: 8, cursor: 'pointer' }} />
                        </Popover>
                      </div>
                      <div className="seat-category">
                        <div className="seat-color" style={{ backgroundColor: '#52ed52' }}></div>
                        <span>Selected Seat</span>
                        <Popover content="chosen seats for your journey.">
                          <InfoCircleOutlined style={{ marginLeft: 8, cursor: 'pointer' }} />
                        </Popover>
                      </div>
                      <div className="seat-category">
                        <div className="seat-color" style={{ backgroundColor: 'black' }}></div>
                        <span>Others</span>
                        <Popover content="Seats are available for selection but don't have any special benefits.">
                          <InfoCircleOutlined style={{ marginLeft: 8, cursor: 'pointer' }} />
                        </Popover>
                      </div>
                      <div className="seat-category">
                        <div className="seat-color" style={{ backgroundColor: 'gray' }}></div>
                        <span>Occupied Seat</span>
                        <Popover content="Seats are already taken by other passengers.">
                          <InfoCircleOutlined style={{ marginLeft: 8, cursor: 'pointer' }} />
                        </Popover>
                      </div>
                    </div>
                  </div>
                }

              </div>

              <div className="seatlayoutbox">
                {ssrResponse?.specialServiceRequest?.[id]?.supplier === "TBO" ? (



                  <div key={`flight_${id}`} className="seatsss">
                    <div className="seatinner" >
                      {flights?.SEATDYNAMIC?.[0]?.SSRSEATS?.map((seat, a) => {
                        if (seat?.SegmentSeat?.length > 0) {
                          return seat.SegmentSeat.map((segment, b) => {
                            if (
                              segment?.RowSeats?.length > 1 &&
                              segment.RowSeats[1].Seats[0].origin === origin &&
                              segment.RowSeats[1].Seats[0].destination === dest
                            ) {
                              return (
                                <div className="flightbox" id={`FlBox_${id}_${a}_Seg_${b}`} key={`segment_${id}_${a}_${b}`}>
                                  <h1 className="flightdetails">
                                    {segment.RowSeats[1].Seats[0].origin} to {segment.RowSeats[1].Seats[0].destination}
                                  </h1>
                                  <div className="flightbody">
                                    <div className="flgtrow">
                                      <span className="leftwing"></span>
                                      <div className="innerrow">
                                        <h2>Front Entrance</h2>
                                      </div>
                                      <br />
                                      <div className="innerrow">
                                        <h2>Deck {segment.RowSeats[1].Seats[0].deck}</h2>
                                      </div>
                                      <br />
                                      <span className="rightwing"></span>
                                    </div>
                                    {segment.RowSeats.slice(1).map((row, c) => {
                                      if (row.Seats.length > 0) {
                                        let rowClass = "seatrow";
                                        let divClass = "";
                                        let divID = "";
                                        const isExitRow = row.Seats.some(seat => seat.seatType?.includes("Exit"));
                                        const rowSeatCount = row.Seats.length;




                                        row.Seats.forEach((seatInfo, d) => {
                                          if (d == 0) {
                                            if (
                                              seatInfo.seatType?.includes("Wing") &&
                                              !(segment.RowSeats[c - 1]?.Seats[d]?.seatType?.includes("Wing"))
                                            ) {
                                              divClass += " windowrow";
                                              divID = "topwing";
                                            } else if (
                                              seatInfo.seatType?.includes("Wing") &&
                                              !(segment.RowSeats[c + 1]?.Seats[d]?.seatType?.includes("Wing"))
                                            ) {
                                              divClass += " windowrow";
                                              divID = "bottomwing";
                                            } else if (seatInfo.seatType?.includes("Wing")) {
                                              divClass += " windowrow";
                                            }
                                          }
                                        });

                                        return (
                                          <div key={`row_${id}_${a}_${b}_${c}`} className={`flgtrow ${rowClass} ${divClass}`} id={`${divID}`}

                                          >
                                            <span className="leftwing"></span>
                                            <div className="innerrow">
                                              {isExitRow && (
                                                <div className={`flgtrow`}>
                                                  <span className="leftwings"></span>
                                                  <div className="innerrow">
                                                    <b className="emergencydoor">
                                                      <span className="leftdoor">«</span>
                                                      <span className="emergencytext">Emergency Door</span>
                                                      <span className="rightdoor">»</span>
                                                    </b>
                                                    <h6></h6>
                                                  </div>
                                                  <span className="rightwings"></span>
                                                </div>
                                              )}
                                              <ul className="seatlist">
                                                {row.Seats.map((seatInfo, d) => {
                                                  let seatClass = "seat";
                                                  const seatCode = seatInfo.code || "";
                                                  const isSeatSelected = Object.keys(selectedSeats[id] || {}).some(key => {
                                                    const selectedSeat = selectedSeats[id][key];
                                                    const segmentIndex = parseInt(key.split('_')[3]);

                                                    return selectedSeat.code === seatInfo.code && segmentIndex === b;
                                                  });
                                                  const isSeatBookedOrReserved = seatInfo.availablityType === "Reserved" || seatInfo.availablityType === "CheckedIn" || seatInfo.availablityType === "FleetBlocked";

                                                  if (d === 0) {
                                                    if (seatInfo.seatType?.includes("Recline")) {
                                                      rowClass += " recline";
                                                    } else if (seatInfo.seatType?.includes("Bulkhead")) {
                                                      rowClass += " recline";
                                                    }
                                                    if (seatInfo.seatType?.includes("Quiet")) {
                                                      rowClass += " quietzone";
                                                    }

                                                  }
                                                  if (seatInfo.code?.includes("NoSeat")) {
                                                    seatClass += " blankseat";
                                                  }
                                                  if (seatInfo.seatType?.includes("Bulkhead")) {
                                                    seatClass += " bulkhead";
                                                  }
                                                  if (d < rowSeatCount) {
                                                    if (
                                                      seatInfo.seatType?.includes("NotSet") &&
                                                      row.Seats[d + 1] &&
                                                      row.Seats[d + 1].seatType.includes("Aisle")
                                                    ) {
                                                      seatClass += " gallery";
                                                    } else if (
                                                      row.Seats[d + 1] &&
                                                      seatInfo.seatType?.includes("Aisle") &&
                                                      row.Seats[d].seatType.includes("Aisle")
                                                    ) {
                                                      seatClass += " gallery";
                                                    } else if (
                                                      row.Seats[d + 1] &&
                                                      seatInfo.seatType?.includes("Aisle") &&
                                                      row.Seats[d + 1].seatType?.includes("NotSet")
                                                    ) {
                                                      seatClass += " gallery";
                                                    }
                                                  }
                                                  if (isSeatBookedOrReserved) {
                                                    seatClass += " booked";
                                                  } else if (isSeatSelected) {
                                                    seatClass += " selected";
                                                  }

                                                  return (
                                                    <li
                                                      key={`seat_${id}_${a}_${b}_${c}_${d}`}
                                                      className={`${seatClass}`}
                                                      style={{
                                                        marginRight:
                                                          d < rowSeatCount &&
                                                            seatInfo.seatType?.includes("Aisle") &&
                                                            row.Seats[d + 1]?.seatType?.includes("Aisle")
                                                            ? "25px"
                                                            : "0",
                                                      }}
                                                      onClick={() => {
                                                        if (selectedPassenger != null) {
                                                          handleSeatSelection(seatInfo, passengertype, selectedPassenger, id, b);
                                                        } else {
                                                          message.error("Please select a passenger first.");
                                                        }
                                                      }}
                                                    >
                                                      <span id={`FlSeat_${id}_${a}_${b}_Row_${c}_Seat_${d}`}>{seatCode}</span>
                                                      <div className="seatdetails" id={`FlSeatInfo_${id}_${a}_${b}_Row_${c}_Seat_${d}`}>
                                                        <ul className="seatinfo">
                                                          {isSeatBookedOrReserved ? (
                                                            <li>Sorry! This seat is taken</li>
                                                          ) : (
                                                            <>
                                                              <li>Seat No: {seatInfo.code}</li>
                                                              <li>Price: {currencyValue(Number(seatInfo.price).toFixed(2))} {activeCurrency}</li>
                                                            </>
                                                          )}
                                                        </ul>
                                                      </div>
                                                    </li>
                                                  );
                                                })}
                                              </ul>
                                            </div>
                                            <span className="rightwing"></span>
                                          </div>
                                        );
                                      }
                                      return null;
                                    })}
                                  </div>
                                </div>
                              );
                            }
                            return null;
                          });
                        }
                        return null;
                      })}
                    </div>
                  </div>
                ) : (

                  <div key={`flight_${id}`} className="seatsss">
                    <div className="seatinner seatinn">
                      {flights?.SEATDYNAMIC?.[0]?.SSRSEATS?.map((seat, a) => {
                        if (seat?.SegmentSeat?.length > 0) {
                          return seat.SegmentSeat.map((segment, b) => {
                            if (
                              segment?.RowSeats?.length > 0 &&
                              segment.RowSeats[0].Seats[0].origin === origin &&
                              segment.RowSeats[0].Seats[0].destination === dest
                            ) {

                              const uniqueXValues = [
                                ...new Set(segment.RowSeats.flatMap(row => row.Seats.map(seatInfo => seatInfo.xValue)))
                              ].sort((a, b) => a - b);  // Sort xValues to ensure correct order

                              const uniqueYValues = [
                                ...new Set(segment.RowSeats.map(row => row.Seats[0]?.yValue))
                              ].sort((a, b) => a - b);  // Sort yValues to ensure correct order

                              return (
                                <div className="flightbox flightbox1" id={`FlBox_${id}_${a}_Seg_${b}`} key={`segment_${id}_${a}_${b}`}>
                                  <h1 className="flightdetails flightdetails11">
                                    {segment.RowSeats[0].Seats[0].origin} to {segment.RowSeats[0].Seats[0].destination}
                                  </h1>
                                  <div className="flightbody" style={{ position: 'relative' }}>
                                    {/* Top Alphabet Labels */}
                                    <div className="top-alphabets" style={{ position: 'relative' }}>
                                      {uniqueXValues.map((xValue, i) => (
                                        <div
                                          key={`alphabet_${id}_${i}`}
                                          className="top-alphabet"
                                          style={{
                                            position: 'absolute',
                                            left: `${xValue * 15}px`,  // Positioning based on xValue
                                            width: '22px',  // Adjust width if necessary to match seat size
                                            textAlign: 'center'
                                          }}
                                        >
                                          {String.fromCharCode(65 + i)} {/* Convert index to Alphabet */}
                                        </div>
                                      ))}
                                    </div>

                                    {segment.RowSeats.map((row, c) => {
                                      if (row.Seats.length > 0) {

                                        const rowYValue = row.Seats[0]?.yValue;
                                        const seatCode = row.Seats[0]?.code || "";
                                        // const rowNumber = uniqueYValues.indexOf(rowYValue) + 1;  // Map yValue to number
                                        const rowNumber = seatCode.match(/\d+/)?.[0];

                                        const lastXValue = uniqueXValues[uniqueXValues.length - 1]; // Get the last xValue

                                        const nextRowValue = segment.RowSeats.length;
                                        const showWing = nextRowValue !== undefined && Math.floor(nextRowValue / 2);

                                        return (
                                          <div className="seatrow" key={`row_${id}_${a}_${b}_${c}`} style={{ position: 'relative' }}>

                                            {showWing === c && (
                                              <div className="wingleft" style={{
                                                position: 'absolute',
                                                top: `${rowYValue * 12}px`,
                                                left: `${-268}px`,  // Adjust as needed to position the wing

                                              }} />
                                            )}

                                            <div
                                              className="row-number"
                                              style={{
                                                position: 'absolute',
                                                left: '-30px',  // Adjust as needed to position the number label
                                                top: `${rowYValue * 15}px`,  // Align number label with row
                                                width: '22px',
                                                textAlign: 'center'
                                              }}
                                            >
                                              {rowNumber}
                                            </div>

                                            {row.Seats.map((seatInfo, d) => {
                                              const seatCode = seatInfo.code || "";
                                              const isSeatSelected = Object.keys(selectedSeats[id] || {}).some(key => {
                                                const selectedSeat = selectedSeats[id][key];
                                                const segmentIndex = parseInt(key.split('_')[3]);

                                                return selectedSeat.code === seatInfo.code && segmentIndex === b;

                                              });

                                              const isSeatBookedOrReserved = ["Reserved", "CheckedIn", "FleetBlocked", "Restricted", "Unknown", "Booked"].includes(seatInfo.availablityType);

                                              const seatImage = isSeatSelected
                                                ? seatSelect  // If the seat is selected, always use seatSelect
                                                : isSeatBookedOrReserved
                                                  ? seatOcc  // If the seat is booked or reserved, use seatOcc
                                                  : seatInfo?.seatType === "EES" && passengertype === "CHD"
                                                    ? seatOcc
                                                    : seatInfo?.description === "Spicemax"
                                                      ? seatSmax  // If the description is Spicemax, use seatSmax
                                                      : seatInfo?.description === "Preferred"
                                                        ? seatPreff  // If the description is Preferred, use seatPref
                                                        : seatIcon;  // Default to seatIcon


                                              let seatClass = "seat";
                                              if (isSeatSelected) {
                                                seatClass += " selected1";
                                              } else if (isSeatBookedOrReserved) {
                                                seatClass += " booked1";
                                              }
                                              const cursorStyle = seatImage === seatOcc ? 'not-allowed' : 'pointer';
                                              return (
                                                <Tooltip
                                                  key={`tooltip_${id}_${a}_${b}_${c}_${d}`}
                                                  title={
                                                    <>
                                                      <div>Seat No: {seatCode}</div>
                                                      <div>Price: <span style={{ color: "#bd0c21" }}>{currencyValue(Number(seatInfo?.price).toFixed(2))}</span> {activeCurrency}</div>
                                                      <div>Seat Type: {(seatInfo?.seatType || seatInfo?.description) ? seatInfo?.seatType && seatInfo?.description : "Others"}</div>
                                                    </>
                                                  }
                                                  placement="top"
                                                >
                                                  <div
                                                    key={`seat_${id}_${a}_${b}_${c}_${d}`}
                                                    className={seatClass}
                                                    style={{
                                                      position: 'absolute',
                                                      top: `${Number(seatInfo.yValue) * 15}px`,
                                                      left: `${Number(seatInfo.xValue) * 15}px`,
                                                      width: '22px',
                                                      height: '20px',
                                                      textAlign: "center",
                                                      cursor: cursorStyle, // Indicate that it's clickable
                                                    }}
                                                    onClick={() => {
                                                      if (seatImage !== seatOcc) {  // Prevent click if seat is booked/reserved
                                                        if (selectedPassenger != null) {
                                                          handleSeatSelection(seatInfo, passengertype, selectedPassenger, id, b);
                                                        } else {
                                                          message.error("Please select a passenger first.");
                                                        }
                                                      } else {
                                                        message.error("This seat is not available for selection.");
                                                      }
                                                    }}
                                                  >
                                                    <img
                                                      src={seatImage}
                                                      alt="Seat"
                                                      style={{
                                                        width: '32px',
                                                        height: '25px',
                                                      }}
                                                    />
                                                  </div>
                                                </Tooltip>
                                              );
                                            })}
                                            {showWing === c && (
                                              <div className="wingright" style={{
                                                position: 'absolute',
                                                top: `${rowYValue * 12}px`,
                                                left: `${(lastXValue + 1) * 1.9}px`,
                                              }} />
                                            )}
                                          </div>

                                        );
                                      }
                                      return null;
                                    })}

                                  </div>
                                </div>
                              );
                            }
                            return null;
                          });
                        }
                        return null;
                      })}
                    </div>
                  </div>

                )}
              </div>








            </div>
          ))}

        </div>



      </Modal>
    </div >
  );
};

export default FlightCheckout;
